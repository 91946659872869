import { CheckCircleOutlined, CloudUploadOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import censusCompensationBoardApi from '../../apis/CensusCompensationBoardApi';
import CensusTransferModal from '../../components/CensusTransferModal/CensusTransferModal';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../contexts/CustomContext';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { BreadcrumbItem, Page } from '../../models/Elements';
import { CensusCompensationBoard, Council } from '../../models/Entities';
import alertService from '../../services/AlertService';
import rolesService from '../../services/RolesService';
import tableService from '../../services/TableService';
import styles from './CensusCompensationBoardsPage.module.scss';
import { StatusType } from '../../models/Types';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';

/**
 * Returns the census compensation boards page.
 * @returns the census compensation boards page.
 */
const CensusCompensationBoardsPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm<string>();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const [censusCompensationBoards, setCensusCompensationBoards] = useState<Page<CensusCompensationBoard>>();
    const [censusTransferModal, setCensusTransferModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [page, setPage] = useState<number>(0);
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [filter, setFilter] = useState<Filter>({});

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const censusCompensationBoards = await censusCompensationBoardApi.list(
                        page,
                        100,
                        'id',
                        sortOrder,
                        council.id,
                        filter.searchText,
                        filter.transferred,
                        undefined
                    );
                    setCensusCompensationBoards(censusCompensationBoards);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortOrder, filter.searchText, filter.transferred, council.id, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterItems = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText,
            transferred: values.transferred
        };
        setFilter(filter);
        console.log('filter', filter);
    };

    const hideModal = () => {
        setCensusTransferModalVisible(false);
    };
    const refresh = async () => {
        setCensusTransferModalVisible(false);
        try {
            if (council && council.id) {
                setLoading('loading');
                const censusCompensationBoards = await censusCompensationBoardApi.list(
                    page,
                    100,
                    'id',
                    sortOrder,
                    council.id,
                    filter.searchText,
                    filter.transferred,
                    undefined
                );
                setCensusCompensationBoards(censusCompensationBoards);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const items = censusCompensationBoards ? censusCompensationBoards.content : [];

    const columns: ColumnsType<CensusCompensationBoard> = [
        {
            title: <FormattedMessage id="censusCompensationBoard.reference" />,
            dataIndex: ['reference'],
            key: 'reference',
            width: 120,
            fixed: 'left',
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.documentId ? (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                ) : (
                    value
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.identifier" />,
            dataIndex: ['taxpayer', 'identifier'],
            key: 'identifier',
            width: 120,
            fixed: 'left',
            align: 'center',
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.documentId ? (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                ) : (
                    value
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.firstName" />,
            dataIndex: ['taxpayer', 'firstName'],
            key: 'firstName',
            width: 220,
            fixed: 'left',
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.documentId ? (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                ) : (
                    value
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.lastName" />,
            dataIndex: ['taxpayer', 'lastName'],
            key: 'lastName',
            width: 180,
            fixed: 'left',
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.documentId ? (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                ) : (
                    value
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.secondLastName" />,
            dataIndex: ['taxpayer', 'secondLastName'],
            key: 'secondLastName',
            width: 180,
            fixed: 'left',
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.documentId ? (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                ) : (
                    value
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.status" />,
            dataIndex: 'status',
            key: 'status',
            width: 120,
            align: 'center',
            render: (value: StatusType, censusCompensationBoard: CensusCompensationBoard) => (
                <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                    <ConstantLabel value={value} prefix="status.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.transferred" />,
            dataIndex: 'transferred',
            key: 'transferred',
            width: 100,
            align: 'center',
            render: (value: boolean, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.transferred && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value && <CheckCircleOutlined />}</Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.street" />,
            dataIndex: ['taxpayer', 'address', 'street'],
            key: 'street',
            width: 160,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.taxpayer?.address?.street && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.city" />,
            dataIndex: ['taxpayer', 'address', 'city'],
            key: 'city',
            width: 160,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.taxpayer?.address?.city && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.principal" />,
            dataIndex: 'principal',
            key: 'principal',
            align: 'right',
            width: 120,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.principal && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {' '}
                        <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.vat" />,
            dataIndex: 'vat',
            key: 'vat',
            align: 'right',
            width: 120,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.vat && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.vatRate" />,
            dataIndex: 'vatRate',
            key: 'vatRate',
            align: 'right',
            width: 120,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.vatRate && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.1" />,
            dataIndex: ['plots', '0', 'identifier'],
            key: 'plot1Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[0]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '0', 'rightsPercentage'],
            key: 'plot1Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[0]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.2" />,
            dataIndex: ['plots', '1', 'identifier'],
            key: 'plot2Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[1]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '1', 'rightsPercentage'],
            key: 'plot2Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[1]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.3" />,
            dataIndex: ['plots', '2', 'identifier'],
            key: 'plot3Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[2]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '2', 'rightsPercentage'],
            key: 'plot3Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[2]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.4" />,
            dataIndex: ['plots', '3', 'identifier'],
            key: 'plot4Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[3]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '3', 'rightsPercentage'],
            key: 'plot4Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[3]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.5" />,
            dataIndex: ['plots', '4', 'identifier'],
            key: 'plot5Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[4]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '4', 'rightsPercentage'],
            key: 'plot5Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[4]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.6" />,
            dataIndex: ['plots', '5', 'identifier'],
            key: 'plot6Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[5]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '5', 'rightsPercentage'],
            key: 'plot6Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[5]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.7" />,
            dataIndex: ['plots', '6', 'identifier'],
            key: 'plot7Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[6]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '6', 'rightsPercentage'],
            key: 'plot7Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[6]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.8" />,
            dataIndex: ['plots', '7', 'identifier'],
            key: 'plot8Identifier',
            align: 'center',
            width: 120,
            render: (value: string, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[7]?.identifier && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>{value}</Link>
                )
        },
        {
            dataIndex: ['plots', '7', 'rightsPercentage'],
            key: 'plot8Rights',
            align: 'right',
            width: 100,
            render: (value: number, censusCompensationBoard: CensusCompensationBoard) =>
                censusCompensationBoard.plots[7]?.rightsPercentage && (
                    <Link to={`/councils/${council?.id}/census-compensation-boards/${censusCompensationBoard.id}`}>
                        {value && <FormattedNumber value={value / 100} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                    </Link>
                )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="receivables.title" />} menu={'councils'} path={PathComponent(council)} council={council}>
            <Form form={form} onFinish={filterItems} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row>
                    <Col span={16}>
                        <Space className={styles.filter}>
                            <Form.Item name="searchText">
                                <Search
                                    placeholder={intl.formatMessage({
                                        id: 'censusCompensationBoards.search'
                                    })}
                                    size="large"
                                    allowClear
                                    className={styles.search}
                                    onSearch={form.submit}
                                />
                            </Form.Item>
                            <Form.Item name="transferred" valuePropName="checked">
                                <Checkbox onChange={form.submit} className={styles.checkbox}>
                                    <FormattedMessage id="censusCompensationBoards.transferred" />
                                </Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col span={8} className={styles.buttons}>
                        <Space>
                            {isAdmin && (
                                <Link to={`/councils/${council?.id}/census-compensation-boards/import`}>
                                    <Tooltip title={<FormattedMessage id="censusCompensationBoards.import.tooltip" />}>
                                        <Button type="primary" danger size="large" icon={<CloudUploadOutlined />}>
                                            {desktop && <FormattedMessage id="button.import" tagName="span" />}
                                        </Button>
                                    </Tooltip>
                                </Link>
                            )}
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="censusCompensationBoards.transfer.tooltip" />}>
                                    <Button type="primary" size="large" icon={<RightCircleOutlined />} onClick={() => setCensusTransferModalVisible(true)}>
                                        {desktop && <FormattedMessage id="button.transfer" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                expandable={{ childrenColumnName: 'taxpayers', defaultExpandAllRows: true }}
                pagination={tableService.createPagination(censusCompensationBoards)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className={`table ${styles.table}`}
                scroll={{ x: 400 }}
            />
            {censusTransferModal && <CensusTransferModal councilId={council.id!} tax="JUNTA_DE_COMPENSACION" onSave={refresh} onCancel={hideModal} />}
        </LayoutComponent>
    );
};
export default CensusCompensationBoardsPage;

interface Filter {
    searchText?: string | undefined;
    transferred?: boolean;
}

/**
 * Returns the path of the census urban properties page.
 * @param council the council
 * @returns  the path
 */
const PathComponent = (council: Council): BreadcrumbItem[] => {
    return [
        { path: '/councils', name: <FormattedMessage id="councils.title" /> },
        { path: `/councils/${council.id}`, name: council.name },
        { path: `/councils/${council.id}/census-compensation-boards`, name: <FormattedMessage id="censusCompensationBoards.title" /> }
    ];
};
