import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import { Dayjs } from 'dayjs';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import billingApi from '../../../apis/BillingApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import { BillingReceivableCancellation, BillingReceivableCancellations } from '../../../models/Entities';
import { ProcessType } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import styles from './BillingCancellationsComponent.module.scss';

/**
 *
 * @param props the props
 * @returns the billing cancellations component
 */
const BillingCancellationsComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate, process } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [billingReceivableCancellation, setBillingReceivableCancellation] = useState<BillingReceivableCancellation>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const billingReceivableCancellation = await billingApi.getCancellations(councilId, startDate, endDate, process);
                setBillingReceivableCancellation(billingReceivableCancellation);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, process, startDate]);

    /*** HOOKS ***/
    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadCancellations(councilId, startDate, endDate, process);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'billingReceivableCancellations.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const columns: ColumnsType<BillingReceivableCancellations> = [
        {
            title: <FormattedMessage id="billingReceivableCancellations.taxpayerIdentifier" />,
            dataIndex: 'taxpayerIdentifier',
            key: 'taxpayerIdentifier',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.councilTaxpayerId" />,
            dataIndex: 'councilTaxpayerId',
            key: 'councilTaxpayerId',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.taxpayerFullname" />,
            dataIndex: 'taxpayerFullname',
            key: 'taxpayerFullname',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.receivableId" />,
            dataIndex: 'receivableId',
            key: 'receivableId',
            align: 'center',
            width: 25
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.tax" />,
            dataIndex: 'tax',
            key: 'tax',
            align: 'center',
            render: (value: string, billingReceivableCancellations: BillingReceivableCancellations) => (
                <ConstantLabel value={value} prefix="receivable.tax.short." />
            )
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.identifier" />,
            dataIndex: 'identifier',
            key: 'identifier',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.receivableTaxObject" />,
            dataIndex: 'receivableTaxObject',
            key: 'receivableTaxObject',
            align: 'center'
        },

        {
            title: <FormattedMessage id="billingReceivableCancellations.remarks" />,
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.canceled" />,
            dataIndex: 'canceled',
            key: 'canceled',
            align: 'center',
            width: 20,
            render: (value: string, billingReceivableCancellations: BillingReceivableCancellations) => (
                <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" />
            )
        },
        {
            title: <FormattedMessage id="billingReceivableCancellations.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'center',
            width: 15,
            render: (value: number, billingReceivableCancellations: BillingReceivableCancellations) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        }
    ];

    const billingReceivableCancellations = billingReceivableCancellation?.billingReceivableCancellations
        ? billingReceivableCancellation.billingReceivableCancellations
        : [];
    return (
        <div className={styles.container}>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <Table
                className={styles.table}
                dataSource={billingReceivableCancellations}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="billingReceivablePayment.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} />
                                <Table.Summary.Cell index={4} />
                                <Table.Summary.Cell index={5} />
                                <Table.Summary.Cell index={6} />
                                <Table.Summary.Cell index={7} />
                                <Table.Summary.Cell index={8} />
                                <Table.Summary.Cell index={9} align="center">
                                    {billingReceivableCancellation && billingReceivableCancellation.amount && (
                                        <FormattedNumber
                                            value={billingReceivableCancellation!.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default BillingCancellationsComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
    process: ProcessType;
}
