import { Dayjs } from 'dayjs';
import {
    BillingPaymentsByCouncilCollectionAccount,
    BillingPaymentsByDay,
    BillingPaymentsByFiscalYearCreatedYearTax,
    BillingReceivableByCharge,
    BillingReceivableCancellation,
    BillingReceivableCancellationsByFiscalYearCreatedYearTax,
    BillingReceivablePaymentsByDay
} from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';
import { ProcessType } from '../models/Types';

class BillingApi {
    getReceivablePaymentDays = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<BillingReceivablePaymentsByDay> => {
        const response = await adminApiAxios.get<BillingReceivablePaymentsByDay>('/billings/receivable-payment-days', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });
        this.loadReceivablePaymentsDates(response.data);

        return response.data;
    };

    downloadReceivablePaymentDays = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>('/billings/receivable-payment-days/spreadsheets', {
            responseType: 'blob',
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    getPaymentDays = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<BillingPaymentsByDay> => {
        const response = await adminApiAxios.get<BillingPaymentsByDay>('/billings/payment-days', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });
        this.loadBillingPaymentsDates(response.data);

        return response.data;
    };

    downloadPaymentDays = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>('/billings/payment-days/spreadsheets', {
            responseType: 'blob',
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    getPaymentCollectionAccounts = async (
        councilId: number,
        startDate: Dayjs,
        endDate: Dayjs,
        process?: ProcessType
    ): Promise<BillingPaymentsByCouncilCollectionAccount> => {
        const response = await adminApiAxios.get<BillingPaymentsByCouncilCollectionAccount>('/billings/payment-collection-accounts', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    downloadPaymentCollectionAccounts = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>('/billings/payment-collection-accounts/spreadsheets', {
            responseType: 'blob',
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    getPaymentFiscalYearCreatedYearTax = async (
        councilId: number,
        startDate: Dayjs,
        endDate: Dayjs,
        process?: ProcessType
    ): Promise<BillingPaymentsByFiscalYearCreatedYearTax> => {
        const response = await adminApiAxios.get<BillingPaymentsByFiscalYearCreatedYearTax>('/billings/payment-fiscal-year-created-tax', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    downloadPaymentFiscalYearCreatedYearTax = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>('/billings/payment-fiscal-year-created-tax/spreadsheets', {
            responseType: 'blob',
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    getCancellations = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<BillingReceivableCancellation> => {
        const response = await adminApiAxios.get<BillingReceivableCancellation>('/billings/receivable-cancellations', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });
        this.loadBillingReceivableCancellationDates(response.data);

        return response.data;
    };

    downloadCancellations = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>('/billings/receivable-cancellations/spreadsheets', {
            responseType: 'blob',
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    getCancellationsByFiscalYearCreatedTax = async (
        councilId: number,
        startDate: Dayjs,
        endDate: Dayjs,
        process: ProcessType
    ): Promise<BillingReceivableCancellationsByFiscalYearCreatedYearTax> => {
        const response = await adminApiAxios.get<BillingReceivableCancellationsByFiscalYearCreatedYearTax>(
            '/billings/receivable-cancellation-fiscal-year-created-tax',
            {
                params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
            }
        );

        return response.data;
    };

    downloadCancellationsByFiscalYearCreatedTax = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process: ProcessType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>('/billings/receivable-cancellations-fiscal-year-created-tax/spreadsheets', {
            responseType: 'blob',
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    getCharges = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<BillingReceivableByCharge> => {
        const response = await adminApiAxios.get<BillingReceivableByCharge>('/billings/receivable-charges', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });
        return response.data;
    };

    downloadCharges = async (councilId: number, startDate: Dayjs, endDate: Dayjs, process?: ProcessType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>('/billings/receivable-charges/spreadsheets', {
            responseType: 'blob',
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON(), process }
        });

        return response.data;
    };

    /**
     * Loads the dates of a billingReceivablePaymentsByDay
     * @param billingReceivablePaymentsByDay - the billingReceivablePaymentsByDay
     */
    private loadReceivablePaymentsDates(billingReceivablePaymentsByDay: BillingReceivablePaymentsByDay) {
        billingReceivablePaymentsByDay &&
            billingReceivablePaymentsByDay.billingReceivablePaymentsDays &&
            billingReceivablePaymentsByDay.billingReceivablePaymentsDays.forEach((brps) => dateService.transformDate(brps.date))!;
    }

    /**
     * Loads the dates of a billingReceivablePaymentsByDay
     * @param billingReceivablePaymentsByDay - the billingReceivablePaymentsByDay
     */
    private loadBillingPaymentsDates(billingPaymentsByDay: BillingPaymentsByDay) {
        billingPaymentsByDay.billingPaymentsDays.forEach((bpd) => dateService.transformDate(bpd.date))!;
    }

    /**
     * Loads the dates of a billingReceivableCancellation
     * @param billingReceivableCancellation - the billingReceivableCancellation
     */
    private loadBillingReceivableCancellationDates(billingReceivableCancellation: BillingReceivableCancellation) {
        billingReceivableCancellation.billingReceivableCancellations.forEach((brc) => dateService.transformDate(brc.canceled))!;
    }
}

const billingApi: BillingApi = new BillingApi();
export default billingApi;
