import { Col, Form, Row, Select, Space, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd/lib';
import { Key, useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import receivableApi from '../../apis/ReceivableApi';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import ReceivableActionsMenu from '../../components/ReceivableActionsMenu/ReceivableActionsMenu';
import CustomContext from '../../contexts/CustomContext';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { BreadcrumbItem, Page } from '../../models/Elements';
import { Council, Receivable, ReceivableFilter } from '../../models/Entities';
import { PhaseType, StateType, TaxType, taxTypes } from '../../models/Types';
import alertService from '../../services/AlertService';
import receivableService from '../../services/ReceivableService';
import rolesService from '../../services/RolesService';
import tableService from '../../services/TableService';
import styles from './VoluntaryReceivablesPage.module.scss';

/**
 * Returns the voluntary receivables page.
 * @returns the voluntary receivables page.
 */
const VoluntaryReceivablesPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm<ReceivableFilter>();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const [receivables, setReceivables] = useState<Page<Receivable>>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [filter, setFilter] = useState<ReceivableFilter>();

    /*** EFFECTS ***/

    // initialize filter
    useEffect(() => {
        const init = async () => {
            if (council.id) {
                const selectedStates = [...receivableService.voluntaryStates, ...receivableService.finalStates].filter(
                    (s) => !['PAID', 'CANCELLED'].includes(s)
                );
                const selectedPhases: PhaseType[] = ['VOLUNTARY'];
                const filter: ReceivableFilter = { status: 'ENABLED', councilId: council.id, phases: selectedPhases, states: selectedStates };
                setFilter(filter);
                form.setFieldsValue(filter);
            }
        };
        init();
    }, [auth, council.id, form]);

    useEffect(() => {
        const init = async () => {
            try {
                if (filter && filter.councilId) {
                    setLoading('loading');
                    const receivables = await receivableApi.list(page, 100, sortField, sortOrder, filter);
                    setReceivables(receivables);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const search = async (values: ReceivableFilter) => {
        const updatedFilter: ReceivableFilter = Object.assign({}, filter, values);
        setFilter(updatedFilter);
    };

    const select = (selectedRowKeys: Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const refresh = async () => {
        try {
            setLoading('loading');
            const receivables = await receivableApi.list(page, 100, sortField, sortOrder, filter);
            setReceivables(receivables);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const rowSelection: TableRowSelection<Receivable> = {
        selectedRowKeys,
        onChange: select
    };

    const stateOptions: DefaultOptionType[] = [
        {
            label: <FormattedMessage id="receivable.phase.VOLUNTARY" />,
            options: receivableService.voluntaryStates.map((s) => ({
                label: <ConstantLabel value={s} prefix="receivable.state." />,
                value: s
            }))
        },
        {
            label: <FormattedMessage id="receivable.phase.FINAL" />,
            options: receivableService.finalStates.map((s) => ({
                label: <ConstantLabel value={s} prefix="receivable.state." />,
                value: s
            }))
        }
    ];
    const taxOptions = taxTypes.map((tt) => ({ value: tt, label: <ConstantLabel value={tt} prefix="receivable.tax.short." /> }));

    const items = receivables ? receivables.content : [];
    const selectedItems = items.filter((item) => selectedRowKeys.includes(item.id as Key));

    const columns: ColumnsType<Receivable> = [
        {
            title: <FormattedMessage id="receivable.reference.short" />,
            dataIndex: 'reference',
            key: 'id',
            width: 180,
            fixed: 'left',
            align: 'center',
            render: (value: string, receivable: Receivable) => <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="councilTaxpayer.id.short" />,
            dataIndex: ['councilTaxpayer', 'id'],
            key: 'id',
            width: 80,
            fixed: 'left',
            align: 'center',
            render: (value: string, receivable: Receivable) => <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="taxpayer.identifier" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'identifier'],
            key: 'identifier',
            width: 140,
            fixed: 'left',
            align: 'center',
            render: (value: string, receivable: Receivable) => <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="taxpayer.fullName" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'fullName'],
            key: 'fullName',
            width: 220,
            fixed: 'left',
            render: (value: string, receivable: Receivable) => <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="receivable.name" />,
            dataIndex: 'tax',
            key: 'tax',
            align: 'center',
            width: 120,
            render: (value: TaxType, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <ConstantLabel value={value} prefix="receivable.tax.short." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'center',
            width: 120,
            render: (value: number, receivable: Receivable) => <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'principal',
            align: 'right',
            width: 200,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="amount.surcharge" />,
            dataIndex: ['amount', 'surcharge'],
            key: 'surcharge',
            align: 'right',
            width: 200,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="amount.interest" />,
            dataIndex: ['amount', 'interest'],
            key: 'interest',
            align: 'right',
            width: 200,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="amount.vat" />,
            dataIndex: ['amount', 'vat'],
            key: 'vat',
            align: 'right',
            width: 200,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="amount.total" />,
            dataIndex: ['amount', 'total'],
            key: 'total',
            align: 'right',
            width: 220,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.phase" />,
            dataIndex: 'phase',
            key: 'phase',
            align: 'center',
            width: 120,
            render: (value: PhaseType, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <Tag color={value === 'APREMIO' ? 'orange' : 'red'} className="tag">
                        <ConstantLabel value={value} prefix="receivable.phase." />
                    </Tag>
                </Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.state" />,
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 250,
            render: (value: StateType, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <ConstantLabel value={value} prefix="receivable.state." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="audit.created" />,
            dataIndex: 'auditCreated',
            key: 'auditCreated',
            sorter: true,
            defaultSortOrder: 'descend',
            align: 'center',
            width: 200,
            render: (value: any, receivable: Receivable) => (
                <Link to={`/councils/${council?.id}/receivables/${receivable.id}`}>
                    <FormattedDate value={receivable.audit!.created! as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="receivables.title" />} menu={'councils'} path={PathComponent(council)} council={council}>
            <Form form={form} onFinish={search} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row>
                    <Col span={20}>
                        <Space className={styles.filter}>
                            <Form.Item name="searchText">
                                <Search
                                    placeholder={intl.formatMessage({
                                        id: 'receivables.search'
                                    })}
                                    size="large"
                                    allowClear
                                    className={styles.search}
                                    onSearch={form.submit}
                                />
                            </Form.Item>
                            <Form.Item name="tax">
                                <Select
                                    placeholder={intl.formatMessage({
                                        id: 'receivables.filter.tax'
                                    })}
                                    size="large"
                                    className={styles.tax}
                                    allowClear
                                    maxTagCount={'responsive'}
                                    onChange={form.submit}
                                    options={[...taxOptions]}
                                />
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col span={4} className={styles.buttons}>
                        <Space>{isAdmin && receivables && <ReceivableActionsMenu receivables={selectedItems} council={council} onAction={refresh} />}</Space>
                    </Col>
                    <Col span={20}>
                        <Form.Item name="states">
                            <Select
                                placeholder={intl.formatMessage({
                                    id: 'receivables.filter.state'
                                })}
                                size="large"
                                className={styles.state}
                                allowClear
                                maxTagCount={'responsive'}
                                onChange={form.submit}
                                mode="multiple"
                                options={[...stateOptions]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(receivables)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                rowSelection={rowSelection}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className={`table ${styles.table}`}
                scroll={{ x: 400 }}
            />
        </LayoutComponent>
    );
};
export default VoluntaryReceivablesPage;

/**
 * Returns the path of the receivables page.
 * @param council the council
 * @returns  the path
 */
const PathComponent = (council: Council): BreadcrumbItem[] => {
    return [
        { path: '/councils', name: <FormattedMessage id="councils.title" /> },
        { path: `/councils/${council.id}`, name: council.name },
        { path: `/councils/${council.id}/receivables`, name: <FormattedMessage id="receivablesVoluntary.title" /> }
    ];
};
