import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Row, Space } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import paymentApi from '../../../../apis/PaymentApi';
import { Payment, Receivable, ReceivablePayment } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivablePaymentComponent.module.scss';

const ReceivablePaymentComponent = (props: Props) => {
    const { payment } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'downloading'>();
    const [paymentPayment, setPaymentPayment] = useState<Payment>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                if (payment.paymentId) {
                    const paymentPayment = await paymentApi.get(payment.paymentId!);
                    setPaymentPayment(paymentPayment);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, payment]);

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            if (paymentPayment) {
                const document = await paymentApi.download(paymentPayment.id!);
                FileSaver.saveAs(document, paymentPayment.file!.name);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'amount',
            label: <FormattedMessage id="receivable.amount.principal" />,
            children: payment.amount && (
                <FormattedNumber
                    value={payment.amount.principal!}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'surcharge',
            label: <FormattedMessage id="receivable.amount.surcharge" />,
            children: payment.amount && (
                <FormattedNumber
                    value={payment.amount.surcharge}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'interest',
            label: <FormattedMessage id="receivable.amount.interest" />,
            children: payment.amount && (
                <FormattedNumber value={payment.amount.interest} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            key: 'vat',
            label: <FormattedMessage id="receivable.amount.vat" />,
            children: payment.amount && (
                <FormattedNumber value={payment.amount.vat} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            key: 'totalAmount',
            label: <FormattedMessage id="receivable.totalAmount" />,
            children: payment.amount && (
                <span className={styles.total}>
                    <strong>
                        <FormattedNumber
                            value={payment.amount.total}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            style={'currency' as any}
                            currency="EUR"
                        />
                    </strong>
                </span>
            )
        },
        {
            key: 'paid',
            label: <FormattedMessage id="receivable.payment.date" />,
            children: paymentPayment && (
                <FormattedDate value={paymentPayment.paid as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={5} />
                </Col>
            </Row>

            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Space>
                        {paymentPayment && (
                            <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                                <FormattedMessage id="button.download" tagName="span" />
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default ReceivablePaymentComponent;

interface Props {
    receivable: Receivable | undefined;
    payment: ReceivablePayment;
}
