import { Principal } from '../models/Entities';
import { adminApiAxios } from './CustomAxios';

class PrincipalApi {
    getCurrent = async (): Promise<Principal> => {
        const response = await adminApiAxios.get<Principal>(`/principals/current`);

        return response.data;
    };
}

const principalApi: PrincipalApi = new PrincipalApi();
export default principalApi;
