import { CheckOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomContext from '../../../../contexts/CustomContext';
import { Receivable } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import rolesService from '../../../../services/RolesService';
import ReceivableVoluntaryResetModal from '../ReceivableInitializationComponent/ReceivableVoluntaryResetModal/ReceivableVoluntaryResetModal';
import ReceivablePaymentModal from '../ReceivablePaymentModal/ReceivablePaymentModal';
import styles from './ReceivableSeizureIncomeComponent.module.scss';

const ReceivableSeizureIncomeComponent = (props: Props) => {
    const { receivable, readonly, onUpdate } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const { auth } = useContext(CustomContext);
    const [paymentModalVisible, setPaymentModalVisible] = useState<boolean>(false);
    const [cancellationModalVisible, setCancellationModalVisible] = useState<boolean>(false);

    /*** EFFECTS ***/

    /*** METHODS ***/

    const save = async () => {
        try {
            setCancellationModalVisible(false);
            await onUpdate();
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    return (
        <>
            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Space>
                        {isAdmin && !readonly && (
                            <Tooltip title={<FormattedMessage id="receivable.paid.tooltip" />} mouseLeaveDelay={0}>
                                <Button type="primary" danger icon={<CheckOutlined />} onClick={() => setPaymentModalVisible(true)}>
                                    <FormattedMessage id="receivable.paid" tagName="span" />
                                </Button>
                            </Tooltip>
                        )}

                        {isAdmin && !readonly && (
                            <Button icon={<UndoOutlined />} onClick={() => setCancellationModalVisible(true)}>
                                <FormattedMessage id="receivable.cancellation.button" tagName="span" />
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>

            {paymentModalVisible && <ReceivablePaymentModal receivable={receivable} onCancel={() => setPaymentModalVisible(false)} onSave={save} />}
            {cancellationModalVisible && (
                <ReceivableVoluntaryResetModal receivable={receivable} onCancel={() => setCancellationModalVisible(false)} onSave={save} />
            )}
        </>
    );
};

export default ReceivableSeizureIncomeComponent;

interface Props {
    receivable: Receivable;
    onUpdate: () => Promise<void>;
    readonly?: boolean;
}
