import { Col } from 'antd';
import React from 'react';
import PublicLayoutComponent from '../../../components/PublicLayoutComponent/PublicLayoutComponent';
import styles from './LegalNoticePage.module.scss';

/**
 * Returns the legal notice page.
 * @returns the legal notice page.
 */
const LegalNoticePage = (): React.ReactElement => {
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <PublicLayoutComponent>
            <Col span={24} className={styles.content}>
                <h1>Aviso legal</h1>
                <div>
                    <p>
                        En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                        Información y del Comercio Electrónico (LSSICE), el propietario de la web le informa de lo siguiente:
                    </p>
                    <ul>
                        <li>Denominación social: Walfen Technologies SLU</li>
                        <li>Inscrita en el Registro Mercantil de Murcia, hoja número MU-96942, inscripción 1a.</li>
                    </ul>
                    <p>
                        Con los límites establecidos en la ley, Walfen Technologies SLU no asume ninguna responsabilidad derivada de la falta de veracidad,
                        integridad, actualización y precisión de los datos o informaciones que contienen sus páginas web.
                    </p>
                    <p>
                        Los contenidos e información no vinculan a Walfen Technologies SLU ni constituyen opiniones, consejos o asesoramiento legal de ningún
                        tipo pues se trata meramente de un servicio ofrecido con carácter informativo y divulgativo.
                    </p>
                    <p>
                        Las páginas de Internet de Walfen Technologies SLU pueden contener enlaces (links) a otras páginas de terceras partes que Walfen
                        Technologies SLU no puede controlar. Por lo tanto, Walfen Technologies SLU no puede asumir responsabilidades por el contenido que pueda
                        aparecer en páginas de terceros.
                    </p>
                    <p>
                        Los textos, imágenes, sonidos, animaciones, software y el resto de contenidos incluidos en este website son propiedad exclusiva de
                        Walfen Technologies SLU o sus licenciantes. Cualquier acto de transmisión, distribución, cesión, reproducción, almacenamiento o
                        comunicación pública total o parcial, deberá contar con el consentimiento expreso de Walfen Technologies SLU.
                    </p>
                    <p>
                        {' '}
                        Asimismo, para acceder a algunos de los servicios que Walfen Technologies SLU ofrece a través del sitio web, deberá proporcionar algunos
                        datos de carácter personal. En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
                        de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre
                        circulación de estos datos le informamos que, mediante la cumplimentación de los presentes formularios, sus datos personales quedarán
                        incorporados y serán tratados en los ficheros de Walfen Technologies SLU con el fin de poderle prestar y ofrecer nuestros servicios así
                        como para informarle de las mejoras del sitio Web.{' '}
                    </p>
                    <p>
                        Le informamos también de que tendrá la posibilidad en todo momento de ejercer los derechos de acceso, rectificación, cancelación,
                        oposición, limitación y portabilidad de sus datos de carácter personal, de manera gratuita mediante email a: info@walfen.com o en la
                        dirección: C/ Ángel Bruna, 50, Esc. 1, 2ºa, 30203 – Cartagena (Murcia).
                    </p>
                </div>
            </Col>
        </PublicLayoutComponent>
    );
};

export default LegalNoticePage;
