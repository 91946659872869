export const authorities = ['ROLE_ADMIN', 'ROLE_COUNCIL_ADMIN', 'ROLE_COUNCIL_USER'] as const;
export type AuthorityType = (typeof authorities)[number];

export const userTypes = ['ADMIN', 'COUNCIL_ADMIN', 'COUNCIL_USER'] as const;
export type UserType = (typeof userTypes)[number];

export const bankCategoryTypes = ['CREDIT_ENTITY', 'CENTRAL_BANK', 'MONEY_MARKET_FUND', 'ANOTHER_INSTITUTION'] as const;
export type BankCategoryType = (typeof bankCategoryTypes)[number];

export const positionTypes = ['MAYOR', 'FINANCE_COUNCILOR', 'CONTROLLER', 'TREASURER', 'ADMINISTRATIVE'] as const;
export type PositionType = (typeof positionTypes)[number];

export const statusTypes = ['ENABLED', 'DISABLED'] as const;
export type StatusType = (typeof statusTypes)[number];

export const taxTypes = [
    'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA',
    'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_RUSTICA',
    'IMPUESTO_SOBRE_ACTIVIDADES_ECONOMICAS',
    'IMPUESTO_DE_VEHICULOS_DE_TRACCION_MECANICA',
    'IMPUESTO_SOBRE_EL_INCREMENTO_DEL_VALOR_DE_LOS_TERRENOS_DE_NATURALEZA_URBANA',
    'TASA_RECOGIDA_Y_TRANSPORTE_DE_BASURA',
    'TASA_ENTRADA_VEHICULOS_A_TRAVES_DE_LAS_ACERAS',
    'SANCION_INFRACCION_TRAFICO',
    'EXPEDIENTE_SANCIONADOR',
    'CUOTAS_URBANISTICAS',
    'JUNTA_DE_COMPENSACION'
] as const;
export type TaxType = (typeof taxTypes)[number];

export const subtaxTypes = [
    'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA',
    'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_RUSTICA',
    'IMPUESTO_SOBRE_ACTIVIDADES_ECONOMICAS', //
    'CICLOMOTORES',
    'MOTOCICLETAS_HASTA_125_CC',
    'MOTOCICLETAS_MAS_125_CC_A_250_CC',
    'MOTOCICLETAS_MAS_250_CC_A_500_CC',
    'MOTOCICLETAS_MAS_500_CC_A_1000_CC',
    'MOTOCICLETAS_MAS_1000_CC',
    'TURISMOS_DE_MENOS_8_HP',
    'TURISMOS_DE_8_A_11_COMA_99_HP',
    'TURISMOS_DE_12_A_15_COMA_99_HP',
    'TURISMOS_DE_16_A_19_COMA_99_HP',
    'TURISMOS_DE_MAS_19_COMA_99_HP',
    'AUTOBUSES_DE_MENOS_21_PZ',
    'AUTOBUSES_DE_21_A_50_PZ',
    'AUTOBUSES_DE_MAS_50_PZ',
    'CAMIONES_DE_MENOS_1000_KG',
    'CAMIONES_DE_1000_A_2999_KG',
    'CAMIONES_DE_3000_A_9999_KG',
    'CAMIONES_DE_MAS_9999_KG',
    'TRACTORES_DE_MENOS_16_HP',
    'TRACTORES_DE_16_HP_A_25_HP',
    'TRACTORES_DE_MAS_25_HP',
    'REMOLQUES_DE_750_A_999_KG',
    'REMOLQUES_DE_1000_A_2999_KG',
    'REMOLQUES_DE_MAS_2999_KG',
    'IMPUESTO_SOBRE_EL_INCREMENTO_DEL_VALOR_DE_LOS_TERRENOS_DE_NATURALEZA_URBANA',
    'RECOGIDA_BASURA_RESIDENCIAL',
    'RECOGIDA_BASURA_COMERCIO',
    'RECOGIDA_BASURA_INDUSTRIAL',
    'ENTRADA_VEHICULOS_GARAJE_2_5_VEH',
    'ENTRADA_VEHICULOS_GARAJE_6_10_VEH',
    'ENTRADA_VEHICULOS_GARAJE_11_25_VEH',
    'ENTRADA_VEHICULOS_GARAJE_26_50_VEH',
    'ENTRADA_VEHICULOS_GARAJE_MAS_50_VEH',
    'SANCION_INFRACCION_TRAFICO',
    'EXPEDIENTE_SANCIONADOR',
    'CUOTAS_URBANISTICAS',
    'JUNTA_DE_COMPENSACION'
] as const;
export type SubtaxType = (typeof subtaxTypes)[number];

export const processTypes = ['VOLUNTARY', 'EXECUTIVE'] as const;
export type ProcessType = (typeof processTypes)[number];

export const phaseTypes = ['VOLUNTARY', 'APREMIO', 'SEIZURE'] as const;
export type PhaseType = (typeof phaseTypes)[number];

export const periodTypes = ['T_1', 'T_2', 'T_3', 'T_4'] as const;
export type PeriodType = (typeof periodTypes)[number];

export const stateTypes = [
    'VOLUNTARY_REGISTERED',
    'VOLUNTARY_SENT',
    'VOLUNTARY_RESET',
    'REGISTERED',
    'INITIALIZED',
    'NOTIFICATION_SIGN',
    'NOTIFICATION_SIGNED',
    'NOTIFICATION_BUILD',
    'NOTIFICATION_UNOFFICIAL',
    'NOTIFICATION_SENT',
    'NOTIFICATION_RECEIVED',
    'NOTIFICATION_ABSENT',
    'NOTIFICATION_WRONG_ADDRESS',
    'NOTIFICATION_UNKNOWN',
    'PAYMENT_ORDER_ANNOUNCEMENT_SIGN',
    'PAYMENT_ORDER_ANNOUNCEMENT_SIGNED',
    'PAYMENT_ORDER_ANNOUNCEMENT_PUBLISHED',
    'SEIZURE',
    'SEIZURE_INCOME',
    'SEIZURE_BANK',
    'CANCELLED',
    'PAID',
    'OVERPAID'
] as const;
export type StateType = (typeof stateTypes)[number];

export const legalEntityTypes = ['NATURAL', 'JURIDICAL'] as const;
export type LegalEntityType = (typeof legalEntityTypes)[number];

export const taxpayerVersionActionTypes = ['CREATE', 'UPDATE', 'DELETE'] as const;
export type TaxpayerVersionActionType = (typeof taxpayerVersionActionTypes)[number];

export const menus = ['dashboard', 'users', 'settings', 'taxes', 'councils', 'taxpayers', 'banks', 'nationalParameters'] as const;
export type MenuType = (typeof menus)[number];

export const sentStateTypes = ['SENT', 'FAILED'] as const;
export type SentStateType = (typeof sentStateTypes)[number];

export const receivableRegistrationFieldTypes = [
    'IDENTIFIER',
    'FIRST_NAME',
    'LAST_NAME',
    'SECOND_LAST_NAME',
    'ADDRESS_STREET',
    'ADDRESS_POSTCODE',
    'ADDRESS_CITY',
    'ADDRESS_PROVINCE',
    'ADDRESS_STATE',
    'ADDRESS_PHONE',
    'ADDRESS_EMAIL',
    'SUBTAX',
    'TAX_OBJECT',
    'AMOUNT_PRINCIPAL',
    'AMOUNT_SURCHARGE',
    'AMOUNT_VAT',
    'FISCAL_YEAR',
    'FISCAL_PERIOD',
    'EXPIRED',
    'REFERENCE'
] as const;
export type ReceivableRegistrationFieldType = (typeof receivableRegistrationFieldTypes)[number];

export const censusImportFieldTypes = [
    //urban property
    'FIX_NUMBER',
    'TAXPAYER_IDENTIFIER',
    'TAXPAYER_FIRST_NAME',
    'TAXPAYER_LAST_NAME',
    'TAXPAYER_SECOND_LAST_NAME',
    'RIGHTS',
    'RIGHTS_PERCENTAGE',
    'PROPERTY_LOCATION',
    'PROPERTY_LOCATION_2',
    'PROPERTY_LOCATION_3',
    'CADASTRAL_REFERENCE',
    'CONSTRUCTED_AREA',
    'LAND_AREA',
    'CONSTRUCTION_VALUE',
    'LAND_VALUE',
    'CADASTRAL_VALUE',
    'TAXABLE_BASE',
    'CLASSIFICATION',
    'TAX_RATE',
    'PARTICIPATION_RATE',
    'BONUS_RATE',
    'BONIFIED_AMOUNT',
    'FEE',
    //compensation board
    'CB_REFERENCE',
    'CB_TAXPAYER_IDENTIFIER',
    'CB_TAXPAYER_FIRST_NAME',
    'CB_TAXPAYER_LAST_NAME',
    'CB_TAXPAYER_SECOND_LAST_NAME',
    'CB_TAXPAYER_ADDRESS_STREET',
    'CB_TAXPAYER_ADDRESS_POSTCODE',
    'CB_TAXPAYER_ADDRESS_CITY',
    'CB_TAXPAYER_ADDRESS_PROVINCE',
    'CB_TAXPAYER_ADDRESS_PHONE',
    'CB_TAXPAYER_ADDRESS_EMAIL',
    'CB_PRINCIPAL',
    'CB_VAT_RATE',
    'CB_VAT',
    'CB_PLOT_1_IDENTIFIER',
    'CB_PLOT_1_RIGHTS_PERCENTAGE',
    'CB_PLOT_2_IDENTIFIER',
    'CB_PLOT_2_RIGHTS_PERCENTAGE',
    'CB_PLOT_3_IDENTIFIER',
    'CB_PLOT_3_RIGHTS_PERCENTAGE',
    'CB_PLOT_4_IDENTIFIER',
    'CB_PLOT_4_RIGHTS_PERCENTAGE',
    'CB_PLOT_5_IDENTIFIER',
    'CB_PLOT_5_RIGHTS_PERCENTAGE',
    'CB_PLOT_6_IDENTIFIER',
    'CB_PLOT_6_RIGHTS_PERCENTAGE',
    'CB_PLOT_7_IDENTIFIER',
    'CB_PLOT_7_RIGHTS_PERCENTAGE',
    'CB_PLOT_8_IDENTIFIER',
    'CB_PLOT_8_RIGHTS_PERCENTAGE'
] as const;
export type CensusImportFieldType = (typeof censusImportFieldTypes)[number];

export const importErrorTypes = ['NOT_NULL', 'MAX_LENGTH', 'EMAIL', 'WRONG_FORMAT', 'DUPLICATE'] as const;
export type ImportErrorType = (typeof importErrorTypes)[number];

export const actionsMenuTypes = ['GLOBAL', 'APREMIO', 'BANK_ACCOUNT_SEIZURE', 'IRPF', 'SALARIES', 'PROPERTY', 'VEHICLES', 'ARCHIVE'] as const;
export type ActionsMenuType = (typeof actionsMenuTypes)[number];

export const councilTaxpayerRemarkTypes = ['VEHICLE', 'REAL_ESTATE', 'SALARY', 'OTHER'] as const;
export type CouncilTaxpayerRemarkType = (typeof councilTaxpayerRemarkTypes)[number];

export const councilTaxpayerSeizureBankPhaseTypes = ['PHASE_1', 'PHASE_2', 'PHASE_3', 'PHASE_4', 'PHASE_5', 'PHASE_6', 'ARCHIVE'] as const;
export type CouncilTaxpayerSeizureBankPhaseType = (typeof councilTaxpayerSeizureBankPhaseTypes)[number];

export const paymentOrderSignSignatureTypes = ['NONE', 'CONTROLLER', 'TREASURER', 'FULL', 'LATEST'] as const;
export type PaymentOrderSignSignatureType = (typeof paymentOrderSignSignatureTypes)[number];

export const announcementSignatureTypes = ['NONE', 'TREASURER', 'FULL', 'LATEST'] as const;
export type AnnouncementSignatureType = (typeof announcementSignatureTypes)[number];

export const paymentTypes = ['MANUAL', 'PAYMENT_ORDER', 'PAYMENT_LETTER'] as const;
export type PaymentType = (typeof paymentTypes)[number];

export const receptionStateTypes = ['RECEIVED', 'ABSENT', 'WRONG_ADDRESS', 'UNKNOWN'] as const;
export type ReceptionStateType = (typeof receptionStateTypes)[number];

export const seizureIncomeTypes = ['SALARY', 'PENSION'] as const;
export type SeizureIncomeType = (typeof seizureIncomeTypes)[number];

export const seizureIncomePhaseTypes = ['REQUEST', 'RESPONSE', 'SIGN', 'SIGNED'] as const;
export type SeizureIncomePhaseType = (typeof seizureIncomePhaseTypes)[number];

export const seizureIncomeStateTypes = ['SENT', 'FAILED'] as const;
export type SeizureIncomeStateType = (typeof seizureIncomeStateTypes)[number];

export const councilTaxpayerSeizureIncomePhaseTypes = [
    'REQUEST',
    'RESPONSE',
    'SIGN',
    'SIGNED',
    'NOTIFICATION_BUILD',
    'NOTIFICATION_SENT',
    'NOTIFICATION_RECEIVED',
    'NOTIFICATION_ABSENT',
    'NOTIFICATION_WRONG_ADDRESS',
    'NOTIFICATION_UNKNOWN',
    'SEIZURE_ORDER_ANNOUNCEMENT_SIGN',
    'SEIZURE_ORDER_ANNOUNCEMENT_SIGNED',
    'SEIZURE_ORDER_ANNOUNCEMENT_PUBLISHED',
    'CANCEL'
] as const;
export type CouncilTaxpayerSeizureIncomePhaseType = (typeof councilTaxpayerSeizureIncomePhaseTypes)[number];

export const seizureIncomeSignSignatureTypes = ['NONE', 'TREASURER', 'FULL', 'LATEST'] as const;
export type SeizureIncomeSignSignatureType = (typeof seizureIncomeSignSignatureTypes)[number];

export const seizureIncomeResponseTypes = ['NOT_REGISTERED', 'EMPLOYED', 'SELF_EMPLOYED', 'PENSION '] as const;
export type SeizureIncomeResponseType = (typeof seizureIncomeResponseTypes)[number];

export const receivableProcedureTypes = ['CERTIFICATION', 'SETTLEMENT'] as const;
export type ReceivableProcedureType = (typeof receivableProcedureTypes)[number];

export const notificationTypes = ['MAIL', 'DEHU'] as const;
export type NotificationType = (typeof notificationTypes)[number];
