import { Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CensusCompensationBoardPlot } from '../../models/Entities';
import alertService from '../../services/AlertService';
import styles from './CensusCompensationBoardPlotModal.module.scss';

/**
 * Returns the modal to edit census compensation board plot.
 * @returns the modal to edit census compensation board plot
 */
const CensusCompensationBoardPlotModal: React.FC<CensusCompensationBoardPlotModalProps> = (props) => {
    const { censusCompensationBoardPlot, onSave, onCancel } = props;

    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'refreshing' | 'deleting'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                form.setFieldsValue(censusCompensationBoardPlot);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [censusCompensationBoardPlot, form, intl]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');
            const updatedCensusCompensationBoardPlot: CensusCompensationBoardPlot = Object.assign({}, censusCompensationBoardPlot, values);
            onSave(updatedCensusCompensationBoardPlot);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = () => {
        onCancel();
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="censusCompensationBoardPlotModal.title" />}
            open={true}
            onCancel={cancel}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{
                loading: loading === 'loading',
                size: 'large',
                htmlType: 'submit'
            }}
            cancelButtonProps={{ size: 'large' }}
            width={700}
            destroyOnClose
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={(values) => save(values)}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]} className={styles.title}>
                <Col xs={24} md={8}>
                    <Form.Item
                        label={<FormattedMessage id="censusCompensationBoardPlotModal.plot.identifier" />}
                        name={['identifier']}
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" maxLength={200} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label={<FormattedMessage id="censusCompensationBoardPlotModal.plot.rights" />}
                        name={['rightsPercentage']}
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <InputNumber size="large" controls={false} className="input-number" addonAfter="%" />
                    </Form.Item>
                </Col>
            </Row>
        </Modal>
    );
};
export default CensusCompensationBoardPlotModal;

interface CensusCompensationBoardPlotModalProps {
    censusCompensationBoardPlot: CensusCompensationBoardPlot;
    onSave: (censusCompensationBoardPlot: CensusCompensationBoardPlot) => void;
    onCancel: () => void;
}
