import { Dayjs } from 'dayjs';
import { Page } from '../models/Elements';
import { CouncilTaxpayerRemark } from '../models/Entities';
import { CouncilTaxpayerRemarkType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilTaxpayerRemarkApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        councilTaxpayerId?: number,
        remarkType?: CouncilTaxpayerRemarkType,
        startDate?: Dayjs,
        endDate?: Dayjs,
        searchText?: string
    ): Promise<Page<CouncilTaxpayerRemark>> => {
        const response = await adminApiAxios.get<Page<CouncilTaxpayerRemark>>('/council-taxpayer-remarks', {
            params: { page, size, sortField, sortOrder, councilId, councilTaxpayerId, remarkType, startDate, endDate, searchText }
        });

        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((remark) => this.loadDates(remark));

        return response.data;
    };

    get = async (councilTaxpayerRemarkId: number): Promise<CouncilTaxpayerRemark> => {
        const response = await adminApiAxios.get<CouncilTaxpayerRemark>(`/council-taxpayer-remarks/${councilTaxpayerRemarkId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (councilTaxpayerRemark: CouncilTaxpayerRemark): Promise<CouncilTaxpayerRemark> => {
        const response = await adminApiAxios.post<CouncilTaxpayerRemark>(`/council-taxpayer-remarks`, councilTaxpayerRemark);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (councilTaxpayerRemark: CouncilTaxpayerRemark): Promise<CouncilTaxpayerRemark> => {
        const response = await adminApiAxios.put<CouncilTaxpayerRemark>(`/council-taxpayer-remarks/${councilTaxpayerRemark.id}`, councilTaxpayerRemark);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (councilTaxpayerRemarkId: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/council-taxpayer-remarks/${councilTaxpayerRemarkId}`);

        return response.data;
    };

    /**
     * Loads the dates of a councilTaxpayerRemark.
     * @param councilTaxpayerRemark - the councilTaxpayerRemark
     */
    private loadDates(councilTaxpayerRemark: CouncilTaxpayerRemark) {
        councilTaxpayerRemark.published = dateService.transformDate(councilTaxpayerRemark.published)!;
        councilTaxpayerRemark.audit!.created = dateService.transformDate(councilTaxpayerRemark.audit!.created)!;
        councilTaxpayerRemark.audit!.updated = dateService.transformDate(councilTaxpayerRemark.audit!.updated)!;
    }
}

const councilTaxpayerRemarkApi: CouncilTaxpayerRemarkApi = new CouncilTaxpayerRemarkApi();
export default councilTaxpayerRemarkApi;
