import { CloudUploadOutlined, DeleteOutlined, EyeOutlined, PaperClipOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    InputNumber,
    List,
    message,
    Modal,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
    Space,
    Tooltip,
    Upload,
    UploadFile
} from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import councilCollectionAccountApi from '../../apis/CouncilCollectionAccountApi';
import paymentApi from '../../apis/PaymentApi';
import paymentLetterApi from '../../apis/PaymentLetterApi';
import paymentOrderApi from '../../apis/PaymentOrderApi';
import {
    CouncilCollectionAccount,
    CouncilTaxpayer,
    PaymentCreation,
    PaymentDraft,
    PaymentLetter,
    PaymentOrder,
    Receivable,
    ReceivablePayment
} from '../../models/Entities';
import { PaymentType, paymentTypes } from '../../models/Types';
import alertService from '../../services/AlertService';
import ConstantLabel from '../ConstantLabel/ConstantLabel';
import FileSizeComponent from '../FileSizeComponent/FileSizeComponent';
import styles from './PaymentModal.module.scss';

const PaymentModal: React.FC<Props> = (props) => {
    const { councilTaxpayer } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<'loading' | 'saving' | 'previewing'>();
    const [paymentType, setPaymentType] = useState<PaymentType>();
    const [paymentOrders, setPaymentOrders] = useState<PaymentOrder[]>([]);
    const [paymentLetters, setPaymentLetters] = useState<PaymentLetter[]>([]);
    const [paymentDraft, setPaymentDraft] = useState<PaymentDraft>();
    const [councilCollectionAccounts, setCouncilCollectionAccounts] = useState<CouncilCollectionAccount[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const councilCollectionAccountsPage = await councilCollectionAccountApi.list(0, 1000, 'iban', true, councilTaxpayer!.councilId, 'ENABLED');
                const councilCollectionAccounts = councilCollectionAccountsPage.content;
                setCouncilCollectionAccounts(councilCollectionAccounts);

                const paymentOrdersPage = await paymentOrderApi.list(0, 1000, 'auditCreated', false, undefined, councilTaxpayer!.id);
                const paymentOrders = paymentOrdersPage.content;
                const paymentLettersPage = await paymentLetterApi.list(0, 1000, 'auditCreated', false, undefined, councilTaxpayer!.id);
                const paymentLetters = paymentLettersPage.content;

                setPaymentOrders(paymentOrders);
                setPaymentLetters(paymentLetters);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilTaxpayer, form, intl]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('saving');

            let paymentCreation: PaymentCreation | undefined;
            if (paymentType === 'MANUAL' && values.amount) {
                paymentCreation = {
                    councilTaxpayerId: councilTaxpayer!.id,
                    councilCollectionAccountId: values.councilCollectionAccountId,
                    paid: values.paid,
                    amount: values.amount
                };
            } else if (paymentType === 'PAYMENT_ORDER' && values.paymentOrderId) {
                paymentCreation = {
                    councilTaxpayerId: councilTaxpayer!.id,
                    councilCollectionAccountId: values.councilCollectionAccountId,
                    paid: values.paid,
                    paymentOrderId: values.paymentOrderId
                };
            } else if (paymentType === 'PAYMENT_LETTER' && values.paymentLetterId) {
                paymentCreation = {
                    councilTaxpayerId: councilTaxpayer!.id,
                    councilCollectionAccountId: values.councilCollectionAccountId,
                    paid: values.paid,
                    paymentLetterId: values.paymentLetterId
                };
            }

            if (paymentCreation) {
                const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
                await paymentApi.create(paymentCreation, attachment);
                await props.onSave();
                message.success(intl.formatMessage({ id: 'status.saved' }));
            }
        } catch (error: any) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const preview = async () => {
        try {
            setLoading('previewing');

            const values = await form.validateFields();

            let paymentCreation: PaymentCreation | undefined;
            if (paymentType === 'MANUAL' && values.amount) {
                paymentCreation = {
                    councilTaxpayerId: councilTaxpayer!.id,
                    councilCollectionAccountId: values.councilCollectionAccountId,
                    paid: values.paid,
                    amount: values.amount
                };
            } else if (paymentType === 'PAYMENT_ORDER' && values.paymentOrderId) {
                paymentCreation = {
                    councilTaxpayerId: councilTaxpayer!.id,
                    councilCollectionAccountId: values.councilCollectionAccountId,
                    paid: values.paid,
                    paymentOrderId: values.paymentOrderId
                };
            } else if (paymentType === 'PAYMENT_LETTER' && values.paymentLetterId) {
                paymentCreation = {
                    councilTaxpayerId: councilTaxpayer!.id,
                    councilCollectionAccountId: values.councilCollectionAccountId,
                    paid: values.paid,
                    paymentLetterId: values.paymentLetterId
                };
            }
            let paymentDraft: PaymentDraft | undefined;
            if (paymentCreation) {
                paymentDraft = await paymentApi.createDraft(paymentCreation);
            }
            setPaymentDraft(paymentDraft);
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    const changePaymentType = (event: RadioChangeEvent): void => {
        form.resetFields(['amount', 'paymentLetterId', 'paymentOrderId']);
        setPaymentDraft(undefined);
        setPaymentType(event.target.value as PaymentType);
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    const receivablePayments = paymentDraft ? paymentDraft.receivablePayments : [];
    const receivables = paymentDraft ? paymentDraft.receivables : [];
    const paymentTypeOptions = paymentTypes.map((paymentType) => (
        <Radio.Button key={paymentType} value={paymentType}>
            <ConstantLabel value={paymentType} prefix="payment." />
        </Radio.Button>
    ));
    const paymentOrderOptions = paymentOrders.map((paymentOrder) => ({
        value: paymentOrder.id,
        label: (
            <span>
                {paymentOrder.id} -{' '}
                <FormattedDate value={paymentOrder.audit?.created as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            </span>
        )
    }));

    const paymentLetterOptions = paymentLetters.map((paymentLetter) => ({
        value: paymentLetter.id,
        label: (
            <span>
                {' '}
                {paymentLetter.id} -{' '}
                <FormattedDate value={paymentLetter.audit?.created as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            </span>
        )
    }));

    const councilCollectionAccountsOptions = councilCollectionAccounts.map((cca) => ({
        value: cca.id,
        label: (
            <span>
                {cca.bank?.name} - {cca.iban}
            </span>
        )
    }));

    return (
        <Modal
            title={<FormattedMessage id="paymentModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{
                loading: loading === 'saving',
                size: 'large',
                htmlType: 'submit'
            }}
            cancelButtonProps={{ size: 'large' }}
            width={1100}
            destroyOnClose
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={(values) => save(values)}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]} className={styles.container}>
                <Col span={24}>
                    <Row gutter={28}>
                        <Col span={24}>
                            <Form.Item
                                name="file"
                                valuePropName="files"
                                label={
                                    <>
                                        <span>
                                            <FormattedMessage id="seizureBankInfoResponseModal.upload" />
                                        </span>
                                    </>
                                }
                                rules={[
                                    {
                                        validator: validateFile
                                    },
                                    {
                                        required: true,
                                        message: <FormattedMessage id="status.mandatory" />
                                    }
                                ]}
                                extra={
                                    <>
                                        <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                                    </>
                                }
                            >
                                <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                                    <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                        {files.length > 0 && (
                            <Col span={12}>
                                <List
                                    className={styles.files}
                                    itemLayout="horizontal"
                                    dataSource={files}
                                    locale={{ emptyText: <></> }}
                                    renderItem={(file) => (
                                        <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                            <List.Item.Meta
                                                avatar={<PaperClipOutlined className={styles.icon} />}
                                                title={file.name}
                                                description={<FileSizeComponent value={file.size} />}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label={<FormattedMessage id="paymentModal.paid" />}
                                name="paid"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <DatePicker className={styles.date} size="large" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<FormattedMessage id="paymentModal.councilCollectionAccount" />}
                                name="councilCollectionAccountId"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select size="large" options={[...councilCollectionAccountsOptions]} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.type}>
                        <Col span={12}>
                            <Form.Item name="paymentType" label={<FormattedMessage id="paymentModal.type" />}>
                                <Radio.Group onChange={changePaymentType} value={paymentType} buttonStyle="outline" size="large">
                                    {paymentTypeOptions}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="paymentModal.amount" />} hidden={paymentType !== 'MANUAL'}>
                                <Row gutter={[8, 0]}>
                                    <Col span={12}>
                                        <Form.Item name="amount" noStyle>
                                            <InputNumber size="large" controls={false} addonAfter="€" className={styles.inputNumber} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Tooltip title={<FormattedMessage id="button.preview" />}>
                                            <Button
                                                icon={<EyeOutlined />}
                                                size="large"
                                                type="primary"
                                                ghost
                                                onClick={preview}
                                                loading={loading === 'previewing'}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="paymentModal.paymentOrder" />} hidden={paymentType !== 'PAYMENT_ORDER'}>
                                <Row gutter={[8, 0]}>
                                    <Col span={20}>
                                        <Form.Item name="paymentOrderId" noStyle>
                                            <Select size="large" options={[...paymentOrderOptions]} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Tooltip title={<FormattedMessage id="button.preview" />}>
                                            <Button
                                                icon={<EyeOutlined />}
                                                size="large"
                                                type="primary"
                                                ghost
                                                onClick={preview}
                                                loading={loading === 'previewing'}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="paymentModal.paymentLetter" />} hidden={paymentType !== 'PAYMENT_LETTER'}>
                                <Row gutter={[8, 0]}>
                                    <Col span={20}>
                                        <Form.Item name="paymentLetterId" noStyle>
                                            <Select size="large" options={[...paymentLetterOptions]} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Tooltip title={<FormattedMessage id="button.preview" />}>
                                            <Button
                                                icon={<EyeOutlined />}
                                                size="large"
                                                type="primary"
                                                ghost
                                                onClick={preview}
                                                loading={loading === 'previewing'}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Divider children={<FormattedMessage id="paymentModal.draft" />} orientation="left" />

                    {paymentDraft && paymentDraft.payment.costs !== undefined && (
                        <p>
                            <strong>
                                <FormattedMessage id="paymentModal.draft.costs" />:
                            </strong>{' '}
                            <FormattedMessage
                                id="paymentModal.draft.costs.amount"
                                values={{
                                    amount: intl.formatNumber(paymentDraft.payment.costs, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR'
                                    }),
                                    total: intl.formatNumber(councilTaxpayer!.costs, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR'
                                    })
                                }}
                            />
                        </p>
                    )}

                    <List
                        className={styles.list}
                        size="small"
                        itemLayout="horizontal"
                        dataSource={receivablePayments}
                        loading={loading === 'previewing'}
                        renderItem={(receivablePayment) => <ReceivablePaymentComponent receivablePayment={receivablePayment} receivables={receivables} />}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default PaymentModal;

interface Props {
    councilTaxpayer: CouncilTaxpayer;
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const ReceivablePaymentComponent: React.FC<ReceivablePaymentComponentProps> = (props) => {
    const { receivablePayment, receivables } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    /*** METHODS ***/

    /*** VISUAL ***/

    const receivable = receivables.find((r) => r.id === receivablePayment.receivableId)!;

    return (
        <List.Item className={styles.item}>
            <List.Item.Meta
                title={
                    <>
                        <ConstantLabel value={receivable.tax} prefix="receivable.tax." /> - {receivable.fiscalYear}
                    </>
                }
                description={
                    <Space split="|">
                        <span>
                            <FormattedMessage
                                id="paymentModal.draft.principal"
                                values={{
                                    amount: intl.formatNumber(receivablePayment.amount!.principal, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR'
                                    }),
                                    total: intl.formatNumber(receivable.amount!.principal, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR'
                                    })
                                }}
                            />
                        </span>
                        <FormattedMessage
                            id="paymentModal.draft.surcharge"
                            values={{
                                amount: intl.formatNumber(receivablePayment.amount!.surcharge, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'EUR'
                                }),
                                total: intl.formatNumber(receivable.amount!.surcharge, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'EUR'
                                })
                            }}
                        />
                        <FormattedMessage
                            id="paymentModal.draft.interest"
                            values={{
                                amount: intl.formatNumber(receivablePayment.amount!.interest, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'EUR'
                                }),
                                total: intl.formatNumber(receivable.amount!.interest, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'EUR'
                                })
                            }}
                        />
                        <FormattedMessage
                            id="paymentModal.draft.vat"
                            values={{
                                amount: intl.formatNumber(receivablePayment.amount!.vat, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'EUR'
                                }),
                                total: intl.formatNumber(receivable.amount!.vat, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'EUR'
                                })
                            }}
                        />
                        <strong>
                            <FormattedMessage
                                id="paymentModal.draft.total"
                                values={{
                                    amount: intl.formatNumber(receivablePayment.amount!.total, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR'
                                    }),
                                    total: intl.formatNumber(receivable.amount!.total, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR'
                                    })
                                }}
                            />
                        </strong>
                    </Space>
                }
            />
        </List.Item>
    );
};

interface ReceivablePaymentComponentProps {
    receivablePayment: ReceivablePayment;
    receivables: Receivable[];
}
