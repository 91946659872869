import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd/lib';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { CensusCompensationBoard, ImportError } from '../../../../models/Entities';
import { CensusImportFieldType } from '../../../../models/Types';
import FormattedCellComponent from '../FormattedCellComponent/FormattedCellComponent';
import styles from './CensusCompensationBoardTableComponent.module.scss';

/**
 * Returns the census compensation board table component.
 * @returns the census compensation board table  component.
 */
const CensusCompensationBoardTableComponent: React.FC<Props> = (props) => {
    const { censuses } = props;

    /***VISUAL***/

    const columns: ColumnsType<CensusCompensationBoard> = [
        {
            dataIndex: 'errors',
            key: 'errors',
            width: 50,
            fixed: 'left',
            align: 'center',
            render: (errors: ImportError<CensusImportFieldType>[]) => {
                if (errors && errors.length > 0) {
                    return (
                        <Tooltip title={<FormattedMessage id="censusDocuments.import.steps.2.status.error" />}>
                            <WarningOutlined className={`${styles.status} ${styles.error}`} />
                        </Tooltip>
                    );
                } else {
                    return <CheckCircleOutlined className={`${styles.status} ${styles.success}`} />;
                }
            }
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.reference" />,
            dataIndex: ['reference'],
            key: 'reference',
            width: 140,
            fixed: 'left',
            align: 'center',
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.identifier" />,
            dataIndex: ['taxpayer', 'identifier'],
            key: 'taxpayerIdentifier',
            width: 140,
            fixed: 'left',
            align: 'center',
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.firstName" />,
            dataIndex: ['taxpayer', 'firstName'],
            key: 'firstName',
            width: 160,
            fixed: 'left',
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_FIRST_NAME">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.lastName" />,
            dataIndex: ['taxpayer', 'lastName'],
            key: 'lastName',
            width: 140,
            fixed: 'left',
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_LAST_NAME">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.secondLastName" />,
            dataIndex: ['taxpayer', 'secondLastName'],
            key: 'lastName',
            width: 160,
            fixed: 'left',
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_SECOND_LAST_NAME">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.street" />,
            dataIndex: ['taxpayer', 'address', 'street'],
            key: 'street',
            width: 160,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_ADDRESS_STREET">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.city" />,
            dataIndex: ['taxpayer', 'address', 'city'],
            key: 'city',
            width: 160,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_ADDRESS_CITY">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.postcode" />,
            dataIndex: ['taxpayer', 'address', 'postcode'],
            key: 'postcode',
            width: 100,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_ADDRESS_POSTCODE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.province" />,
            dataIndex: ['taxpayer', 'address', 'province'],
            key: 'province',
            width: 160,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_ADDRESS_PROVINCE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.phone" />,
            dataIndex: ['taxpayer', 'address', 'phone'],
            key: 'phone',
            width: 160,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_ADDRESS_PHONE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.taxpayer.address.email" />,
            dataIndex: ['taxpayer', 'address', 'email'],
            key: 'email',
            width: 140,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_TAXPAYER_ADDRESS_EMAIL">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.principal" />,
            dataIndex: 'principal',
            key: 'principal',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PRINCIPAL">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.vat" />,
            dataIndex: 'vat',
            key: 'vat',
            align: 'right',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_VAT">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.vatRate" />,
            dataIndex: 'vatRate',
            key: 'vatRate',
            align: 'right',
            width: 120,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_VAT_RATE">
                    <FormattedNumber value={value / 100.0} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.1" />,
            dataIndex: ['plots', '0', 'identifier'],
            key: 'plot1Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_1_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '0', 'rights'],
            key: 'plot1Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_1_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.2" />,
            dataIndex: ['plots', '1', 'identifier'],
            key: 'plot2Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_2_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '1', 'rights'],
            key: 'plot2Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_2_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.3" />,
            dataIndex: ['plots', '2', 'identifier'],
            key: 'plot3Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_3_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '2', 'rights'],
            key: 'plot3Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_3_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.4" />,
            dataIndex: ['plots', '3', 'identifier'],
            key: 'plot4Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_4_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '3', 'rights'],
            key: 'plot4Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_4_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.5" />,
            dataIndex: ['plots', '4', 'identifier'],
            key: 'plot5Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_5_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '4', 'rights'],
            key: 'plot5Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_5_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.6" />,
            dataIndex: ['plots', '5', 'identifier'],
            key: 'plot6Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_6_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '5', 'rights'],
            key: 'plot6Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_6_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.7" />,
            dataIndex: ['plots', '6', 'identifier'],
            key: 'plot7Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_7_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '6', 'rights'],
            key: 'plot7Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_7_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier.8" />,
            dataIndex: ['plots', '7', 'identifier'],
            key: 'plot8Identifier',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_8_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rightsPercentage" />,
            dataIndex: ['plots', '7', 'rights'],
            key: 'plot8Rights',
            align: 'right',
            width: 100,
            render: (value: number, census: CensusCompensationBoard) => (
                <FormattedCellComponent census={census} field="CB_PLOT_8_RIGHTS_PERCENTAGE">
                    {value && <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} style={'percent' as any} />}
                </FormattedCellComponent>
            )
        }
    ];

    return (
        <Table
            dataSource={censuses}
            columns={columns}
            sortDirections={['ascend', 'descend']}
            showSorterTooltip={false}
            pagination={false}
            className="table"
            scroll={{ x: 2000 }}
        />
    );
};
export default CensusCompensationBoardTableComponent;

interface Props {
    censuses: CensusCompensationBoard[];
}
