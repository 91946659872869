import { Tooltip } from 'antd/lib';
import { FormattedMessage } from 'react-intl';
import { Census, ImportError } from '../../../../models/Entities';
import { CensusImportFieldType } from '../../../../models/Types';
import styles from './FormattedCellComponent.module.scss';
import { PropsWithChildren } from 'react';

/**
 * Returns the styling for a cell with import error.
 * @returns the styling for a cell with import error.
 */

const FormattedCellComponent: React.FC<FormattedCellProps> = (props) => {
    const { children, census, field } = props;

    /***VISUAL***/

    const errors = census.errors ? census.errors.filter((e) => e.field === field) : [];

    if (errors.length > 0) {
        return (
            <span className={styles.errorCell}>
                <FormattedCellTooltip errors={errors} />
            </span>
        );
    } else {
        return <>{children}</>;
    }
};

export default FormattedCellComponent;

interface FormattedCellProps extends PropsWithChildren {
    census: Census;
    field: CensusImportFieldType;
}

/**
 * Returns the formatted text in the tooltip.
 * @returns the formatted text in the tooltip.
 */
const FormattedCellTooltip: React.FC<FormattedCellTooltipProps> = (props) => {
    const { errors } = props;

    const value = errors[0].value;
    const titles = (
        <>
            {errors.map((error) => (
                <>
                    <FormattedMessage id={`censusUrbanProperty.import.error.${error.error}`} values={{ parameter1: error.parameter1 }} />
                    <br />
                </>
            ))}
        </>
    );

    return <Tooltip title={titles}>{value || '---'}</Tooltip>;
};
interface FormattedCellTooltipProps {
    errors: ImportError<CensusImportFieldType>[];
}
