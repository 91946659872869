import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Divider, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import censusCompensationBoardApi from '../../../../apis/CensusCompensationBoardApi';
import receivableVoluntaryRegistrationApi from '../../../../apis/ReceivableVoluntaryRegistrationApi';
import ProvinceComponent from '../../../../components/ProvinceComponent/ProvinceComponent';
import { CensusCompensationBoard, CensusCompensationBoardPlot, ReceivableVoluntaryRegistrationCompensationBoard } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableVoluntaryRegistrationCompensationBoardComponent.module.scss';

const ReceivableVoluntaryRegistrationCompensationBoardComponent = (props: Props) => {
    const { registrationCompensationBoard } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [censusCompensationBoard, setCensusCompensationBoard] = useState<CensusCompensationBoard>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const censusCompensationBoard = await censusCompensationBoardApi.get(registrationCompensationBoard.censusCompensationBoardId!);
                setCensusCompensationBoard(censusCompensationBoard);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, registrationCompensationBoard.censusCompensationBoardId]);

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await receivableVoluntaryRegistrationApi.download(registrationCompensationBoard.id!, 'JUNTA_DE_COMPENSACION');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'receivablesVoluntary.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const plots: CensusCompensationBoardPlot[] = censusCompensationBoard?.plots ? censusCompensationBoard?.plots : [];

    const items: DescriptionsProps['items'] = [
        {
            key: 'firstName',
            label: <FormattedMessage id="taxpayer.firstName" />,
            children: censusCompensationBoard?.taxpayer?.firstName
        },
        {
            key: 'lastName',
            label: <FormattedMessage id="taxpayer.lastName" />,
            children: censusCompensationBoard?.taxpayer?.lastName
        },
        {
            key: 'secondLastName',
            label: <FormattedMessage id="taxpayer.secondLastName" />,
            children: censusCompensationBoard?.taxpayer?.secondLastName
        },
        {
            key: 'identifier',
            label: <FormattedMessage id="taxpayer.identifier" />,
            children: censusCompensationBoard?.taxpayer?.identifier
        },
        {
            key: 'phone',
            label: <FormattedMessage id="address.phone" />,
            children: censusCompensationBoard?.taxpayer?.address?.phone
        },
        {
            key: 'email',
            label: <FormattedMessage id="address.email" />,
            children: censusCompensationBoard?.taxpayer?.address?.email
        },
        {
            key: 'street',
            label: <FormattedMessage id="address.street" />,
            children: censusCompensationBoard?.taxpayer?.address?.street
        },
        {
            key: 'city',
            label: <FormattedMessage id="address.city" />,
            children: censusCompensationBoard?.taxpayer?.address?.city
        },
        {
            key: 'province',
            label: <FormattedMessage id="address.province" />,
            children: <ProvinceComponent provinceId={censusCompensationBoard?.taxpayer?.address?.province} />
        },
        {
            key: 'postcode',
            label: <FormattedMessage id="address.postcode" />,
            children: censusCompensationBoard?.taxpayer?.address?.postcode
        },
        {
            key: 'created',
            label: <FormattedMessage id="audit.created" />,
            children: censusCompensationBoard?.audit && (
                <FormattedDate
                    value={censusCompensationBoard.audit.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                />
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>
            {plots.length > 0 && <PlotsComponent plots={plots} />}
            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                        <FormattedMessage id="button.download" tagName="span" />
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default ReceivableVoluntaryRegistrationCompensationBoardComponent;

interface Props {
    registrationCompensationBoard: ReceivableVoluntaryRegistrationCompensationBoard;
}

/**
 * The plots component with the plot information.
 * @param props the props
 * @returns the plots component
 */
const PlotsComponent: React.FC<PlotsComponentProps> = (props) => {
    const { plots } = props;

    /*** HOOKS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    const columns: ColumnsType<CensusCompensationBoardPlot> = [
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier" />,
            dataIndex: ['identifier'],
            key: 'identifier'
        },

        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rights" />,
            dataIndex: 'rightsPercentage',
            key: 'rightsPercentage',
            width: 160,
            align: 'right',
            render: (value: number) => <FormattedNumber value={value / 100} minimumFractionDigits={2} maximumFractionDigits={2} style={'percent' as any} />
        }
    ];

    return (
        <>
            <Divider orientation="left" plain>
                <FormattedMessage id="censusCompensationBoard.plots" />
            </Divider>
            <Col span={6}>
                <Table
                    dataSource={plots}
                    columns={columns}
                    rowKey="id"
                    size="small"
                    //onChange={handleTableChange}
                    showSorterTooltip={false}
                    pagination={false}
                    className={`table ${styles.table}`}
                    scroll={{ x: 400 }}
                />
            </Col>
        </>
    );
};
interface PlotsComponentProps {
    plots: CensusCompensationBoardPlot[];
}
