import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, InputNumber, List, Modal, Row, Upload, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import TextArea from 'antd/es/input/TextArea';
import { UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import receivablePaymentApi from '../../../../apis/ReceivablePaymentApi';
import FileSizeComponent from '../../../../components/FileSizeComponent/FileSizeComponent';
import { Receivable, ReceivablePayment } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivablePaymentModal.module.scss';

const ReceivablePaymentModal = (props: Props) => {
    const { receivable } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [currentPayment, setCurrentPayment] = useState<ReceivablePayment>();
    const [files, setFiles] = useState<UploadFile[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                let currentPayment: ReceivablePayment = {
                    amount: receivable?.amount,
                    receivableId: receivable?.id,
                    partial: false
                };
                setCurrentPayment(currentPayment);
                form.setFieldsValue(currentPayment);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, receivable]);

    /*** METHODS ***/
    //TODO, to check updatedPayment (how it is constructed, check total)
    const save = async () => {
        try {
            setLoading('saving');
            const values = await form.validateFields();
            let updatedPayment: ReceivablePayment = Object.assign({}, currentPayment, values);
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            await receivablePaymentApi.create(updatedPayment, attachment);
            props.onSave();
            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="receivable.paid.modal.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={5}>
                        <Form.Item
                            label={<FormattedMessage id="amount.principal" />}
                            name={['amount', 'principal']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            label={<FormattedMessage id="amount.surcharge" />}
                            name={['amount', 'surcharge']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label={<FormattedMessage id="amount.interest" />}
                            name={['amount', 'interest']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber controls={false} className="input-number" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            label={<FormattedMessage id="councilTaxpayer.costs" />}
                            name={['amount', 'costs']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            label={<FormattedMessage id="amount.vat" />}
                            name={['amount', 'vat']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label={<FormattedMessage id="amount.total" />}
                            name={['amount', 'total']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            label={<FormattedMessage id="receivable.payment.date" />}
                            name="received"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessage id="receivable.payment.modal.remarks" />} name="remarks">
                            <TextArea rows={6} maxLength={800} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name="file"
                            valuePropName="files"
                            label={
                                <>
                                    <span>
                                        <FormattedMessage id="receivable.payment.modal.upload" />
                                    </span>
                                </>
                            }
                            rules={[
                                {
                                    validator: validateFile
                                },
                                { required: true, message: <FormattedMessage id="status.mandatory" /> }
                            ]}
                            extra={
                                <>
                                    <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                                </>
                            }
                        >
                            <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                                <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <List
                            className={styles.files}
                            itemLayout="horizontal"
                            dataSource={files}
                            locale={{ emptyText: <></> }}
                            renderItem={(file) => (
                                <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                    <List.Item.Meta
                                        avatar={<PaperClipOutlined className={styles.icon} />}
                                        title={file.name}
                                        description={<FileSizeComponent value={file.size} />}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default ReceivablePaymentModal;

interface Props {
    receivable: Receivable;
    onSave: () => void;
    onCancel: () => void;
}
