import { Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ReceivableVoluntaryReset } from '../../../../models/Entities';

const ReceivableVoluntaryResetComponent = (props: Props) => {
    const { voluntaryReset } = props;

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'created',
            label: <FormattedMessage id="receivable.voluntaryReset.expired" />,
            children: voluntaryReset.expired && <FormattedDate value={voluntaryReset.expired as any} day="2-digit" month="2-digit" year="numeric" />
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>
        </>
    );
};

export default ReceivableVoluntaryResetComponent;

interface Props {
    voluntaryReset: ReceivableVoluntaryReset;
}
