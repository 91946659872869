import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Col, Form, List, message, Row, Select, Space, Tag, Tooltip } from 'antd/lib';
import Search from 'antd/lib/input/Search';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import councilTaxpayerRemarkApi from '../../../../apis/CouncilTaxpayerRemarkApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import useResponsiveLayout from '../../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomCouncilContext from '../../../../contexts/CustomCouncilContext';
import CustomCouncilTaxpayerContext from '../../../../contexts/CustomCouncilTaxpayerContext';
import { CouncilTaxpayerRemark } from '../../../../models/Entities';
import { CouncilTaxpayerRemarkType, councilTaxpayerRemarkTypes } from '../../../../models/Types';
import alertService from '../../../../services/AlertService';
import CouncilTaxpayerRemarkModal from './CouncilTaxpayerRemarkModal/CouncilTaxpayerRemarkModal';
import styles from './CouncilTaxpayerRemarksComponent.module.scss';

/**
 * The council taxpayer component with the remarks information.
 * @param props the props
 * @returns the council taxpayer remarks component
 */
const CouncilTaxpayerRemarksComponent: React.FC = () => {
    /*** HOOKS ***/
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const { council } = useContext(CustomCouncilContext);
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [councilTaxpayerRemarkModalVisible, setCouncilTaxpayerRemarkModalVisible] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState<'loading' | 'deleting'>();
    const [form] = Form.useForm<CouncilTaxpayerRemarkFilter>();
    const [filter, setFilter] = useState<CouncilTaxpayerRemarkFilter | undefined>();
    const [id, setId] = useState<number>();
    const [pageSize, setPageSize] = useState<number>(5);
    const [data, setData] = useState<CouncilTaxpayerRemark[]>([]);
    const [list, setList] = useState<CouncilTaxpayerRemark[]>([]);

    useEffect(() => {
        const init = async () => {
            try {
                const responseData = await councilTaxpayerRemarkApi.list(
                    0,
                    pageSize,
                    'published',
                    false,
                    undefined,
                    councilTaxpayer!.id,
                    filter?.remarkType,
                    undefined,
                    undefined,
                    filter?.searchText
                );
                setInitLoading(false);
                setData(responseData.content);
                setList(responseData.content);
            } catch (error) {
                console.log(error);
            }
        };
        init();
    }, [council.id, councilTaxpayer, filter?.remarkType, filter?.searchText, pageSize]);

    /*** METHODS ***/

    const save = async () => {
        try {
            setCouncilTaxpayerRemarkModalVisible(false);
            refresh();
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const cancel = () => {
        setCouncilTaxpayerRemarkModalVisible(false);
        setId(undefined);
    };

    const selectItem = (id: number) => {
        setId(id);
        setCouncilTaxpayerRemarkModalVisible(true);
    };

    const refresh = async () => {
        try {
            setPageSize(5);
            const responseData = await councilTaxpayerRemarkApi.list(
                0,
                pageSize,
                'published',
                false,
                undefined,
                councilTaxpayer!.id,
                filter?.remarkType,
                undefined,
                undefined,
                filter?.searchText
            );
            setInitLoading(false);
            setData(responseData.content);
            setList(responseData.content);
            setId(undefined);
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const deleteItem = async (id: number) => {
        try {
            setLoading('deleting');
            await councilTaxpayerRemarkApi.delete(id);
            message.success(intl.formatMessage({ id: 'status.deleted' }));
            refresh();
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const search = async (values: CouncilTaxpayerRemarkFilter) => {
        const updatedFilter: CouncilTaxpayerRemarkFilter = Object.assign({}, filter, values);
        setFilter(updatedFilter);
    };

    const onLoadMore = async () => {
        setLoading('loading');
        setPageSize(pageSize + 5);
        const responseData = await councilTaxpayerRemarkApi.list(
            0,
            pageSize,
            'published',
            false,
            undefined,
            councilTaxpayer!.id,
            filter?.remarkType,
            undefined,
            undefined,
            filter?.searchText
        );
        setData(responseData.content);
        setList(responseData.content);
        setLoading(undefined);
    };

    const loadMore =
        !initLoading && !loading ? (
            <div className={styles.loadMore}>
                {data.length !== 0 && (
                    <Button loading={loading === 'loading'} onClick={onLoadMore}>
                        <FormattedMessage id="button.loadMore" />
                    </Button>
                )}
            </div>
        ) : null;

    /*** VISUAL ***/

    const remarkOptions = councilTaxpayerRemarkTypes.map((ctrt) => ({
        value: ctrt,
        label: <ConstantLabel value={ctrt} prefix="councilTaxpayerRemarks.type." />
    }));

    return (
        <>
            <Form form={form} onFinish={search} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row gutter={[0, 0]}>
                    <Col span={18}>
                        <Space className={styles.filter}>
                            <Form.Item name="searchText">
                                <Search
                                    placeholder={intl.formatMessage({
                                        id: 'councilTaxpayerRemarks.search'
                                    })}
                                    size="large"
                                    allowClear
                                    className={styles.search}
                                    onSearch={form.submit}
                                />
                            </Form.Item>
                            <Form.Item name="remarkType">
                                <Select
                                    placeholder={intl.formatMessage({
                                        id: 'councilTaxpayerRemarks.filter.type'
                                    })}
                                    size="large"
                                    className={styles.tax}
                                    allowClear
                                    onChange={form.submit}
                                    options={remarkOptions}
                                />
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Tooltip title={<FormattedMessage id="users.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setCouncilTaxpayerRemarkModalVisible(true)}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <List
                loading={initLoading}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={list}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Button size="small" icon={<EditOutlined />} onClick={() => selectItem(item.id!)} className={styles.button}></Button>,
                            <Popconfirm
                                title={intl.formatMessage({ id: 'councilTaxpayerRemark.deleteModal.title' })}
                                description={intl.formatMessage({ id: 'councilTaxpayerRemark.deleteModal.text' })}
                                onConfirm={() => deleteItem(item.id!)}
                                okText={intl.formatMessage({ id: 'common.yes' })}
                                cancelText={intl.formatMessage({ id: 'common.no' })}
                            >
                                <Button size="small" icon={<DeleteOutlined />} danger loading={loading === 'deleting'} className={styles.button}></Button>
                            </Popconfirm>
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <Space>
                                    <Tag color="default" className={styles.text}>
                                        <ConstantLabel value={item.remarkType} prefix="councilTaxpayerRemarks.type." />
                                    </Tag>
                                    {item.published && <div className={styles.date}>{item.published.format('LL')}</div>}
                                </Space>
                            }
                            description={item.content}
                        />
                    </List.Item>
                )}
            />
            {councilTaxpayerRemarkModalVisible && <CouncilTaxpayerRemarkModal id={id} onSave={save} onCancel={cancel} />}
        </>
    );
};
export default CouncilTaxpayerRemarksComponent;

interface CouncilTaxpayerRemarkFilter {
    searchText?: string;
    remarkType?: CouncilTaxpayerRemarkType;
}
