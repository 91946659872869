import { MailOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PublicLayoutComponent from '../../components/PublicLayoutComponent/PublicLayoutComponent';
import contact from '../../resources/images/contact.svg';
import styles from './ContactPage.module.scss';

/**
 * Returns the contact page.
 * @returns the contact page.
 */
const ContactPage: React.FC = () => {
    // initialize app
    useEffect(() => {
        const init = async () => {};
        init();
    }, []);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <PublicLayoutComponent>
            <div className={styles.pageWrapper}>
                <div className={styles.container}>
                    <section className={styles.textContainer}>
                        <h2>
                            <FormattedMessage id="contact.header" />
                        </h2>
                        <p>
                            <FormattedMessage id="contact.description.1" />
                        </p>
                        <div className={styles.email}>
                            <MailOutlined className={styles.icon} />
                            <a href="mailto:info@walfen.com">info@walfen.com</a>
                        </div>
                    </section>
                    <section className={styles.imageContainer}>
                        <img src={contact} alt="" className={styles.image} aria-hidden="true" />
                    </section>
                </div>
            </div>
        </PublicLayoutComponent>
    );
};
export default ContactPage;
