import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { BillingPaymentsByDay, BillingPaymentsDay } from '../../../models/Entities';
import { useEffect, useState } from 'react';
import billingApi from '../../../apis/BillingApi';
import alertService from '../../../services/AlertService';
import { Dayjs } from 'dayjs';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styles from './BillingPaymentsByDayComponent.module.scss';
import { FileExcelOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';
import { ProcessType } from '../../../models/Types';

/**
 *
 * @param props the props
 * @returns the billing receivable payments component
 */
const BillingPaymentsByDayComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate, process } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [paymentsByDay, setPaymentsByDay] = useState<BillingPaymentsByDay>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const paymentsByDay = await billingApi.getPaymentDays(councilId, startDate, endDate, process);
                setPaymentsByDay(paymentsByDay);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, process, startDate]);

    /*** HOOKS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadPaymentDays(councilId, startDate, endDate, process);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'billingPaymentsDay.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const columns: ColumnsType<BillingPaymentsDay> = [
        {
            title: <FormattedMessage id="billingPaymentsDay.date" />,
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 20,
            render: (value: string, billingPaymentsDay: BillingPaymentsDay) => (
                <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsDay.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsDay: BillingPaymentsDay) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsDay.amount.surcharge" />,
            dataIndex: ['amount', 'surcharge'],
            key: 'amountSurcharge',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsDay: BillingPaymentsDay) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsDay.amount.interest" />,
            dataIndex: ['amount', 'interest'],
            key: 'amountInterest',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsDay: BillingPaymentsDay) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsDay.amount.vat" />,
            dataIndex: ['amount', 'vat'],
            key: 'amountVat',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsDay: BillingPaymentsDay) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsDay.costs" />,
            dataIndex: 'costs',
            key: 'costs',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsDay: BillingPaymentsDay) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsDay.total" />,
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsDay: BillingPaymentsDay) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },

        {
            title: <FormattedMessage id="billingPaymentsDay.count" />,
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            width: 15
        }
    ];

    const billingPaymentDays = paymentsByDay?.billingPaymentsDays ? paymentsByDay?.billingPaymentsDays : [];
    return (
        <div className={styles.container}>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <Table
                className={styles.table}
                dataSource={billingPaymentDays}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="billingReceivablePayment.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align="center">
                                    {paymentsByDay && paymentsByDay.amount && (
                                        <FormattedNumber
                                            value={paymentsByDay.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} align="center">
                                    {paymentsByDay && paymentsByDay.amount && (
                                        <FormattedNumber
                                            value={paymentsByDay.amount.surcharge}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} align="center">
                                    {paymentsByDay && paymentsByDay.amount && (
                                        <FormattedNumber
                                            value={paymentsByDay.amount.interest}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} align="center">
                                    {paymentsByDay && paymentsByDay.amount && (
                                        <FormattedNumber
                                            value={paymentsByDay.amount.vat}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} align="center">
                                    {paymentsByDay && paymentsByDay.costs && (
                                        <FormattedNumber
                                            value={paymentsByDay!.costs}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6} align="center">
                                    {paymentsByDay && paymentsByDay.total && (
                                        <FormattedNumber
                                            value={paymentsByDay!.total}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7} align="center">
                                    {paymentsByDay && paymentsByDay.count && <FormattedNumber value={paymentsByDay!.count} />}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default BillingPaymentsByDayComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
    process: ProcessType;
}
