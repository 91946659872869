import { Button, Col, Form, InputNumber, message, Modal, Row } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import alertService from '../../../src/services/AlertService';
import paymentLetterApi from '../../apis/PaymentLetterApi';
import { CouncilTaxpayer, PaymentLetterCreation, PaymentLetterDraft, Receivable, ReceivablePaymentLetter } from '../../models/Entities';
import ConstantLabel from '../ConstantLabel/ConstantLabel';
import styles from './PaymentLetterModal.module.scss';

const PaymentLetterModal: React.FC<Props> = (props) => {
    const { receivables, councilTaxpayer } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const receivablePaymentLettersFormValue = Form.useWatch(['receivablePaymentLetters'], form);
    const [loading, setLoading] = useState<'loading' | 'saving' | 'downloading'>();
    const [paymentLetterDraft, setPaymentLetterDraft] = useState<PaymentLetterDraft>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                const receivablePaymentLetters: ReceivablePaymentLetter[] = receivables.map((r) => ({
                    receivableId: r.id,
                    amount: r.amount!
                }));
                const paymentLetterCreation: PaymentLetterCreation = {
                    councilTaxpayerId: councilTaxpayer!.id,
                    costs: councilTaxpayer!.costs,
                    receivablePaymentLetters: receivablePaymentLetters
                };
                const paymentLetterDraft = await paymentLetterApi.createDraft(paymentLetterCreation);

                setPaymentLetterDraft(paymentLetterDraft);
                form.setFieldsValue(paymentLetterDraft);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilTaxpayer, form, intl, receivables]);

    // update receivable payment letter totals
    useEffect(() => {
        const init = async () => {
            try {
                if (receivablePaymentLettersFormValue) {
                    const receivablePaymentLetters: ReceivablePaymentLetter[] = receivablePaymentLettersFormValue;
                    receivablePaymentLetters.forEach((receivablePaymentLetter, index) => {
                        const total =
                            receivablePaymentLetter.amount.principal + receivablePaymentLetter.amount.surcharge + receivablePaymentLetter.amount.interest;
                        form.setFieldValue(['receivablePaymentLetters', index, 'amount', 'total'], total);
                    });
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, receivablePaymentLettersFormValue]);

    /*** METHODS ***/

    const save = async () => {
        try {
            setLoading('saving');

            const values = await form.validateFields();
            const paymentLetterCreation: PaymentLetterCreation = {
                councilTaxpayerId: councilTaxpayer!.id,
                costs: values.paymentLetter.councilTaxpayer.costs,
                receivablePaymentLetters: values.receivablePaymentLetters
            };
            await paymentLetterApi.create(paymentLetterCreation);
            await props.onSave();

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const download = async () => {
        try {
            setLoading('downloading');

            const values = await form.validateFields();
            const paymentLetterCreation: PaymentLetterCreation = {
                councilTaxpayerId: councilTaxpayer!.id,
                costs: values.paymentLetter.councilTaxpayer.costs,
                receivablePaymentLetters: values.receivablePaymentLetters
            };

            const document = await paymentLetterApi.downloadDraft(paymentLetterCreation);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentLetter.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/
    const maxCosts = councilTaxpayer?.costs ? councilTaxpayer.costs : 0;

    return (
        <Modal
            title={<FormattedMessage id="paymentLetterModal.title" />}
            open={true}
            width={800}
            onCancel={cancel}
            footer={[
                <Button key="cancel" size="large" onClick={cancel}>
                    <FormattedMessage id="button.cancel" tagName="span" />
                </Button>,
                <Button
                    key="downloadDraft"
                    size="large"
                    onClick={download}
                    loading={loading === 'downloading'}
                    disabled={!paymentLetterDraft || paymentLetterDraft.receivablePaymentLetters.length === 0}
                >
                    <FormattedMessage id="button.preview" tagName="span" />
                </Button>,
                <Button
                    key="ok"
                    size="large"
                    type="primary"
                    onClick={save}
                    loading={loading === 'saving'}
                    disabled={!paymentLetterDraft || paymentLetterDraft.receivablePaymentLetters.length === 0}
                >
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
            ]}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                <Col span={24} className={styles.description}>
                    <FormattedMessage id="paymentLetterModal.description" />
                </Col>
                <Col span={24}>
                    <Form form={form} colon={false} layout="vertical">
                        <Row gutter={[28, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    name={['paymentLetter', 'councilTaxpayer', 'costs']}
                                    label={<FormattedMessage id="paymentLetterModal.costs" />}
                                    extra={
                                        <span className={styles.extra}>
                                            <FormattedMessage
                                                id="paymentLetterModal.status.value"
                                                values={{
                                                    maxValue: intl.formatNumber(maxCosts, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })
                                                }}
                                            />
                                        </span>
                                    }
                                >
                                    <InputNumber controls={false} className="inputNumber" addonAfter="€" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.List name="receivablePaymentLetters">
                                    {(fields) => (
                                        <>
                                            {fields.map((field, index) => {
                                                const receivablePaymentLetter = paymentLetterDraft?.receivablePaymentLetters[index];
                                                const receivable = receivables.find((r) => r.id === receivablePaymentLetter?.receivableId)!;
                                                const maxPrincipal = receivable.amount!.principal;
                                                const maxSurcharge = receivable.amount!.surcharge;
                                                const maxInterest = receivable.amount!.interest;
                                                const maxVat = receivable.amount!.vat;
                                                const maxTotal = receivable.amount!.total;

                                                return (
                                                    <Row gutter={[28, 0]}>
                                                        <Col span={24}>
                                                            {receivable && (
                                                                <div className={styles.heading}>
                                                                    <ConstantLabel value={receivable?.tax} prefix="receivable.tax." /> -{' '}
                                                                    {receivable?.fiscalYear}
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'amount', 'principal']}
                                                                label={<FormattedMessage id="amount.principal" />}
                                                                extra={
                                                                    <span className={styles.extra}>
                                                                        <FormattedMessage
                                                                            id="paymentLetterModal.status.value"
                                                                            values={{
                                                                                maxValue: intl.formatNumber(maxPrincipal, {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'EUR'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </span>
                                                                }
                                                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    step={0.01}
                                                                    formatter={(value) => (value ? parseFloat(String(value)).toFixed(2) : '0.00')}
                                                                    max={maxPrincipal}
                                                                    controls={false}
                                                                    className="input-number"
                                                                    addonAfter="€"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'amount', 'surcharge']}
                                                                label={<FormattedMessage id="amount.surcharge" />}
                                                                extra={
                                                                    <span className={styles.extra}>
                                                                        <FormattedMessage
                                                                            id="paymentLetterModal.status.value"
                                                                            values={{
                                                                                maxValue: intl.formatNumber(maxSurcharge, {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'EUR'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </span>
                                                                }
                                                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    max={maxSurcharge}
                                                                    step={0.01}
                                                                    formatter={(value) => (value ? parseFloat(String(value)).toFixed(2) : '0.00')}
                                                                    controls={false}
                                                                    className="input-number"
                                                                    addonAfter="€"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'amount', 'interest']}
                                                                label={<FormattedMessage id="amount.interest" />}
                                                                extra={
                                                                    <span className={styles.extra}>
                                                                        <FormattedMessage
                                                                            id="paymentLetterModal.status.value"
                                                                            values={{
                                                                                maxValue: intl.formatNumber(maxInterest, {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'EUR'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </span>
                                                                }
                                                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    max={maxInterest}
                                                                    step={0.01}
                                                                    formatter={(value) => (value ? parseFloat(String(value)).toFixed(2) : '0.00')}
                                                                    controls={false}
                                                                    className="input-number"
                                                                    addonAfter="€"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'amount', 'vat']}
                                                                label={<FormattedMessage id="amount.vat" />}
                                                                extra={
                                                                    <span className={styles.extra}>
                                                                        <FormattedMessage
                                                                            id="paymentLetterModal.status.value"
                                                                            values={{
                                                                                maxValue: intl.formatNumber(maxVat, {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'EUR'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </span>
                                                                }
                                                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    max={maxVat}
                                                                    controls={false}
                                                                    step={0.01}
                                                                    formatter={(value) => (value ? parseFloat(String(value)).toFixed(2) : '0.00')}
                                                                    className="input-number"
                                                                    addonAfter="€"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            {/* TODO: update total on amounts change */}
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'amount', 'total']}
                                                                label={<FormattedMessage id="amount.total" />}
                                                                extra={
                                                                    <span className={styles.extra}>
                                                                        <FormattedMessage
                                                                            id="paymentLetterModal.status.value"
                                                                            values={{
                                                                                maxValue: intl.formatNumber(maxTotal, {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'EUR'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </span>
                                                                }
                                                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    max={maxTotal}
                                                                    step={0.01}
                                                                    formatter={(value) => (value ? parseFloat(String(value)).toFixed(2) : '0.00')}
                                                                    controls={false}
                                                                    className="input-number"
                                                                    addonAfter="€"
                                                                    disabled
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};
export default PaymentLetterModal;

interface Props {
    receivables: Receivable[];
    councilTaxpayer: CouncilTaxpayer;
    onSave: () => Promise<void>;
    onCancel: () => void;
}
