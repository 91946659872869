import { Page } from '../models/Elements';
import { Bank } from '../models/Entities';
import { StatusType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class BankApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        status?: StatusType,
        searchText?: string,
        online?: boolean,
        parent?: boolean,
        councilId?: number,
        parentAndCouncilId?: number
    ): Promise<Page<Bank>> => {
        const response = await adminApiAxios.get<Page<Bank>>('/banks', {
            params: { page, size, sortField, sortOrder, status, searchText, online, parent, councilId, parentAndCouncilId }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((bank) => this.loadDates(bank));

        return response.data;
    };

    get = async (bankId: number): Promise<Bank> => {
        const response = await adminApiAxios.get<Bank>(`/banks/${bankId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (bank: Bank): Promise<Bank> => {
        const response = await adminApiAxios.post<Bank>(`/banks`, bank);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (bank: Bank): Promise<Bank> => {
        const response = await adminApiAxios.put<Bank>(`/banks/${bank.id}`, bank);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (bankId: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/banks/${bankId}`);

        return response.data;
    };

    /**
     * Loads the dates of a bank.
     * @param bank - the bank
     */
    private loadDates(bank: Bank) {
        bank.audit!.created = dateService.transformDate(bank.audit!.created)!;
        bank.audit!.updated = dateService.transformDate(bank.audit!.updated)!;
    }
}

const bankApi: BankApi = new BankApi();
export default bankApi;
