import { ArrowLeftOutlined, CloudDownloadOutlined, FormOutlined, SendOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import paymentOrderApi from '../../apis/PaymentOrderApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import PaymentOrderEventModal from '../../components/PaymentOrderEventModal/PaymentOrderEventModal';
import ReceptionStateTag from '../../components/ReceptionStateTag/ReceptionStateTag';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { CustomAuth, PaymentOrder, Receivable } from '../../models/Entities';
import { ReceptionStateType } from '../../models/Types';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './PaymentOrdersPage.module.scss';
import rolesService from '../../services/RolesService';
import CustomContext from '../../contexts/CustomContext';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import PaymentOrderModal from '../../components/ReceivableActionsMenu/PaymentOrderModal/PaymentOrderModal';

/**
 * Returns the payment orders page.
 * @returns the payment orders page.
 */

const PaymentOrdersPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [desktop] = useResponsiveLayout();
    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [paymentOrdersPage, setPaymentOrdersPage] = useState<Page<PaymentOrder>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [paymentOrder, setPaymentOrder] = useState<PaymentOrder>();
    const [paymentOrderEventModalVisible, setPaymentOrderEventModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'downloading'>();
    const [paymentOrderModalVisible, setPaymentOrderModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const paymentOrdersPage = await paymentOrderApi.list(page, 50, sortField, sortOrder, council.id, undefined, filter.searchText);
                    setPaymentOrdersPage(paymentOrdersPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterPaymentOrders = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (paymentOrder: PaymentOrder) => {
        try {
            setLoading('downloading');
            const document = await paymentOrderApi.download(paymentOrder.id);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrder.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showPaymentOrderEventModal = async (paymentOrder: PaymentOrder) => {
        setPaymentOrder(paymentOrder);
        setPaymentOrderEventModalVisible(true);
    };

    const save = async () => {
        try {
            setLoading('loading');
            setPaymentOrderEventModalVisible(false);
            setPaymentOrderModalVisible(false);
            const paymentOrdersPage = await paymentOrderApi.list(page, 100, sortField, sortOrder, council.id, undefined, filter.searchText);
            setPaymentOrdersPage(paymentOrdersPage);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/
    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const items = paymentOrdersPage ? paymentOrdersPage.content : [];
    const columns: ColumnsType<PaymentOrder> = [
        {
            title: <FormattedMessage id="paymentOrder.taxpayer.id" />,
            dataIndex: ['councilTaxpayer', 'id'],
            key: 'id',
            width: 100
        },
        {
            title: <FormattedMessage id="paymentOrder.taxpayer.name" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'fullName'],
            key: 'fullName'
        },
        {
            title: <FormattedMessage id="paymentOrder.receivables" />,
            dataIndex: 'receivables',
            key: 'receivables',
            width: 160,
            render: (receivables: Receivable[]) => receivables.map((r) => r.reference).join(', '),
            ellipsis: true
        },
        {
            title: <FormattedMessage id="paymentOrder.taxpayer.street" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'street'],
            key: 'street',
            width: 220
        },
        {
            title: <FormattedMessage id="paymentOrder.taxpayer.city" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'city'],
            key: 'city',
            width: 160
        },
        {
            title: <FormattedMessage id="paymentOrder.taxpayer.identifier" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'identifier'],
            key: 'identifier',
            align: 'center',
            width: 120
        },

        {
            title: <FormattedMessage id="paymentOrder.created" />,
            dataIndex: ['audit', 'created'],
            key: 'auditCreated',
            align: 'center',
            width: 140,
            render: (value: any, paymentOrder: PaymentOrder) => <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />
        },
        {
            title: <FormattedMessage id="paymentOrder.receptionState" />,
            dataIndex: 'receptionState',
            key: 'receptionState',
            align: 'center',
            width: 170,
            render: (value: ReceptionStateType | undefined, paymentOrder: PaymentOrder) => <ReceptionStateTag value={value} />
        },
        {
            key: 'actions',
            width: 140,
            align: 'center',
            render: (paymentOrder: PaymentOrder) => (
                <Space size="small">
                    <Tooltip title={<FormattedMessage id="button.download" />}>
                        <Button icon={<CloudDownloadOutlined />} className={styles.icon} onClick={() => download(paymentOrder)} type="link" />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="button.update" />}>
                        <Button
                            icon={<FormOutlined />}
                            className={styles.icon}
                            onClick={() => showPaymentOrderEventModal(paymentOrder)}
                            disabled={!!paymentOrder.receptionState}
                            type="link"
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="paymentOrders.resend" />}>
                        <Button
                            icon={<SendOutlined />}
                            className={styles.icon}
                            //onClick={() => showPaymentOrderResendModal(paymentOrder)}
                            disabled={!['WRONG_ADDRESS', 'UNKNOWN'].includes(paymentOrder.receptionState)}
                            type="link"
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/payment-orders`, name: <FormattedMessage id="paymentOrders.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterPaymentOrders} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'paymentOrders.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="paymentOrders.new.tooltip" />}>
                                    <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setPaymentOrderModalVisible(true)}>
                                        {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(paymentOrdersPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
            {paymentOrderEventModalVisible && paymentOrder && (
                <PaymentOrderEventModal paymentOrderId={paymentOrder.id} onCancel={() => setPaymentOrderEventModalVisible(false)} onSave={save} />
            )}
            {paymentOrderModalVisible && (
                <PaymentOrderModal councilId={council.id!} clone={false} onCancel={() => setPaymentOrderModalVisible(false)} onSave={save} />
            )}
        </LayoutComponent>
    );
};
export default PaymentOrdersPage;

interface Filter {
    searchText?: string | undefined;
}
