import { Button, Col, DatePicker, Form, Row, Select, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { TabsProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import alertService from '../../services/AlertService';
import BillingPaymentsByCouncilCollectionAccountComponent from './BillingPaymentsByCouncilCollectionAccountComponent/BillingPaymentsByCouncilCollectionAccountComponent';
import BillingPaymentsByDayComponent from './BillingPaymentsByDayComponent/BillingPaymentsByDayComponent';
import BillingPaymentsByFiscalYearCreatedYearTaxComponent from './BillingPaymentsByFiscalYearCreatedYearTax/BillingPaymentsByFiscalYearCreatedYearTaxComponent';
import BillingReceivablePaymentsByDayComponent from './BillingReceivablePaymentsByDayComponent/BillingReceivablePaymentsByDayComponent';
import styles from './CouncilBillingPage.module.scss';
import BillingCancellationsComponent from './BillingCancellationsComponent/BillingCancellationsComponent';
import { ProcessType, processTypes } from '../../models/Types';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';

/**
 * Returns the council billing page.
 * @returns the council billing page.
 */

const CouncilBillingPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [form] = useForm();
    const { council } = useContext(CustomCouncilContext);
    const [startDate, setStartDate] = useState<Dayjs>();
    const [endDate, setEndDate] = useState<Dayjs>();
    const [process, setProcess] = useState<ProcessType>('EXECUTIVE');

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    const startDate = dayjs().startOf('month');
                    const endDate = dayjs();
                    setStartDate(startDate);
                    setEndDate(endDate);
                    form.setFieldsValue({ startDate, endDate, process });
                }
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [intl, form, council, process]);

    /*** METHODS ***/

    const filter = (values: any) => {
        setStartDate(values.startDate);
        setEndDate(values.endDate);
        setProcess(values.process);
    };

    /*** VISUAL ***/

    const processOptions = processTypes.map((p) => ({ value: p, label: <ConstantLabel value={p} prefix="process.type." /> }));

    const tabs: TabsProps['items'] = [
        {
            key: 'receivablePaymentsByDay',
            label: <FormattedMessage id="councilBilling.receivablePaymentsByDay" />,
            children: <BillingReceivablePaymentsByDayComponent councilId={council.id!} startDate={startDate!} endDate={endDate!} process={process!} />
        },
        {
            key: 'paymentsByDay',
            label: <FormattedMessage id="councilBilling.paymentsByDay" />,
            children: <BillingPaymentsByDayComponent councilId={council.id!} startDate={startDate!} endDate={endDate!} process={process!} />
        },
        {
            key: 'councilCollectionAccounts',
            label: <FormattedMessage id="councilBilling.councilCollectionAccounts" />,
            children: (
                <BillingPaymentsByCouncilCollectionAccountComponent councilId={council.id!} startDate={startDate!} endDate={endDate!} process={process!} />
            )
        },
        {
            key: 'periods',
            label: <FormattedMessage id="councilBilling.periods" />,
            children: (
                <BillingPaymentsByFiscalYearCreatedYearTaxComponent councilId={council.id!} startDate={startDate!} endDate={endDate!} process={process!} />
            )
        },
        {
            key: 'cancellations',
            label: <FormattedMessage id="councilBilling.cancellations" />,
            children: <BillingCancellationsComponent councilId={council.id!} startDate={startDate!} endDate={endDate!} process={process!} />
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilBanks.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/billing`, name: <FormattedMessage id="councilBilling.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filter} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label={<FormattedMessage id="councilBilling.startDate" />}
                            name="startDate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker className={styles.date} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={<FormattedMessage id="councilBilling.endDate" />}
                            name="endDate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker className={styles.date} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={<FormattedMessage id="councilBilling.process" />} name="process">
                            <Select options={[...processOptions]} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" size="large" htmlType="submit" className={styles.button}>
                            <FormattedMessage id="button.filter" />
                        </Button>
                    </Col>
                </Row>
            </Form>
            {council && council.id && <Tabs defaultActiveKey="receivables" type="card" className={styles.tabs} size="large" items={tabs} />}
        </LayoutComponent>
    );
};
export default CouncilBillingPage;
