import { FormattedMessage, useIntl } from 'react-intl';
import { TaxType } from '../../../../models/Types';
import styles from './TemplateSelectionComponent.module.scss';

/**
 * Returns the template selection component
 * @returns the template selection component
 */

const TemplateSelectionComponent: React.FC<TemplateSelectionProps> = (props) => {
    const { tax } = props;

    /***HOOKS***/

    const intl = useIntl();

    /***VISUAL***/

    return (
        <>
            <span>
                <FormattedMessage id="censusDocuments.import.steps.1.upload" />
            </span>
            {tax === 'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA' && (
                <a
                    href={`${process.env.PUBLIC_URL}/plantilla-padron-ibi-urbana.xlsx`}
                    download={intl.formatMessage({
                        id: 'censusDocuments.import.steps.1.upload.file.1'
                    })}
                    className={styles.link}
                >
                    <FormattedMessage id="censusDocuments.import.steps.1.upload.link" />
                </a>
            )}
            {tax === 'JUNTA_DE_COMPENSACION' && (
                <a
                    href={`${process.env.PUBLIC_URL}/plantilla-padron-junta-de-compensacion.xlsx`}
                    download={intl.formatMessage({
                        id: 'censusDocuments.import.steps.1.upload.file.2'
                    })}
                    className={styles.link}
                >
                    <FormattedMessage id="censusDocuments.import.steps.1.upload.link" />
                </a>
            )}
        </>
    );
};
export default TemplateSelectionComponent;

interface TemplateSelectionProps {
    tax: TaxType;
}
