import { DoubleRightOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Image, Space } from 'antd';
import { Footer, Header } from 'antd/es/layout/layout';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import laptop from '../../resources/images/application.png';
import functionalities from '../../resources/images/illustration.svg';
import { ReactComponent as LogoIcon } from '../../resources/images/taxfen-icon.svg';
import app from '../../resources/images/taxfen-illustration.svg';
import { ReactComponent as TextIcon } from '../../resources/images/taxfen-title-icon.svg';
import styles from './HomePage.module.scss';
/**
 * Returns the home page.
 * @returns the home page.
 */
const HomePage: React.FC = () => {
    /*** HOOKS ***/
    const [desktop] = useResponsiveLayout();

    /*** METHODS ***/
    /*** VISUAL ***/
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <Header className={styles.header}>
                    <Link to="/home" className={styles.title} aria-label="Go to home page">
                        <LogoIcon className={styles.logo} />
                        <TextIcon className={styles.logoTitle} />
                    </Link>
                    <Space size="middle">
                        <Link to="/signin" className={styles.options}>
                            <FormattedMessage id="home.login.link" />
                        </Link>
                        <Link to="/contact" className={styles.options}>
                            <FormattedMessage id="home.contact.link" />
                        </Link>
                    </Space>
                </Header>
                <section className={styles.titleSection}>
                    <div className={styles.text}>
                        <h1>
                            <FormattedMessage id="home.main.header" />
                        </h1>
                        <p>
                            {desktop && <DoubleRightOutlined className={styles.icon} />}
                            <FormattedMessage id="home.main.description.1" />
                        </p>
                        <p>
                            {desktop && <DoubleRightOutlined className={styles.icon} />}
                            <FormattedMessage id="home.main.description.2" />
                        </p>

                        <p>
                            {desktop && <DoubleRightOutlined className={styles.icon} />}
                            <FormattedMessage id="home.main.description.3" />
                        </p>
                        <p>
                            {desktop && <DoubleRightOutlined className={styles.icon} />}
                            <FormattedMessage id="home.main.description.4" />
                        </p>

                        <Link to="/signin">
                            <Button type="primary" className={styles.button}>
                                <FormattedMessage id="button.start" tagName="span" />
                            </Button>
                        </Link>
                    </div>
                    <div className={styles.laptopImage}>
                        <Image src={laptop} className={styles.image} alt="Laptop with Taxfen application open" preview={false} />
                    </div>
                </section>
            </div>
            <section className={styles.benefitsHeader}>
                <h2>
                    <FormattedMessage id="home.main.benefits.header" />
                </h2>
            </section>
            <section className={styles.benefits}>
                <div className={styles.imageContainer}>
                    <img src={app} alt="" aria-hidden="true" className={styles.image} />
                </div>
                <div>
                    <div className={styles.text}>
                        <h3>
                            {desktop && <span className={styles.dot}>01</span>}
                            <FormattedMessage id="home.main.benefits.heading.1" />
                        </h3>
                        <p>
                            <FormattedMessage id="home.main.benefits.description.1" />
                        </p>
                    </div>
                    <div>
                        <div className={styles.text}>
                            <h3>
                                {desktop && <span className={styles.dot}>02</span>}
                                <FormattedMessage id="home.main.benefits.heading.2" />
                            </h3>
                            <p>
                                <FormattedMessage id="home.main.benefits.description.2" />
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className={styles.text}>
                            <h3>
                                {desktop && <span className={styles.dot}>03</span>}
                                <FormattedMessage id="home.main.benefits.heading.3" />
                            </h3>
                            <p>
                                <FormattedMessage id="home.main.benefits.description.3" />
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className={styles.text}>
                            <h3>
                                {desktop && <span className={styles.dot}>04</span>}
                                <FormattedMessage id="home.main.benefits.heading.4" />
                            </h3>
                            <p>
                                <FormattedMessage id="home.main.benefits.description.4" />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.description}>
                <p>
                    <FormattedMessage id="home.main.banner.1" />
                </p>
            </section>
            <section className={styles.functionalitiesHeader}>
                <h2>
                    <FormattedMessage id="home.main.functionalities.header" />
                </h2>
            </section>
            <section className={styles.functionalities}>
                <div className={styles.text}>
                    <p>
                        <DoubleRightOutlined className={styles.icon} />
                        <FormattedMessage id="home.main.functionalities.heading.1" />
                    </p>
                    <p>
                        <DoubleRightOutlined className={styles.icon} />
                        <FormattedMessage id="home.main.functionalities.heading.2" />
                    </p>
                    <p>
                        <DoubleRightOutlined className={styles.icon} />
                        <FormattedMessage id="home.main.functionalities.heading.3" />
                    </p>
                    <p>
                        <DoubleRightOutlined className={styles.icon} />
                        <FormattedMessage id="home.main.functionalities.heading.4" />
                    </p>
                    <p>
                        <DoubleRightOutlined className={styles.icon} />
                        <FormattedMessage id="home.main.functionalities.heading.5" />
                    </p>
                </div>

                <div className={styles.imageContainer}>
                    <Image src={functionalities} alt="" aria-hidden="true" className={styles.image} preview={false} />
                </div>
            </section>
            <section className={styles.contact}>
                <div>
                    <h2>
                        <FormattedMessage id="home.main.contact.header" />
                    </h2>
                    <p>
                        <FormattedMessage id="home.main.contact.description" />
                    </p>
                    <Link to="/contact">
                        <Button type="primary" className={styles.button} icon={<MailOutlined className={styles.icon} />}>
                            <FormattedMessage id="button.contact" tagName="span" />
                        </Button>
                    </Link>
                </div>
            </section>
            <Footer className={styles.footer}>
                <div>
                    <Space className={styles.links} size="large">
                        <Link to="/legal-notice" className={styles.link}>
                            <FormattedMessage id="common.legalNotice" />
                        </Link>
                        <Link to="/privacy-policy" className={styles.link}>
                            <FormattedMessage id="common.privacyPolicy" />
                        </Link>
                    </Space>
                </div>
                <div className={styles.copyright}>
                    <a href="https://walfen.com" target="_blank" rel="noreferrer">
                        ©2025 Walfen Technologies S.L.U.
                    </a>
                </div>
            </Footer>
        </div>
    );
};
export default HomePage;
