import { ReceivablePayment } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class ReceivablePaymentApi {
    get = async (id: number): Promise<ReceivablePayment> => {
        const response = await adminApiAxios.get<ReceivablePayment>(`/receivable-payments/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (receivablePayment: ReceivablePayment, file: any): Promise<ReceivablePayment> => {
        const formData: FormData = this.buildFormData(receivablePayment, file);
        const response = await adminApiAxios.post<ReceivablePayment>(`/receivable-payments`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/receivable-payments/${id}`);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/receivable-payments/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a receivable payment form.
     * @param receivablePayment the receivable payment form
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (receivablePayment: ReceivablePayment, file?: File) => {
        const formData: FormData = new FormData();
        receivablePayment.id && formData.append('id', receivablePayment.id.toString());
        receivablePayment.receivableId && formData.append('receivableId', receivablePayment.receivableId.toString());
        receivablePayment.amount!.principal !== undefined && formData.append('amount.principal', receivablePayment.amount!.principal!.toString());
        receivablePayment.amount!.surcharge! !== undefined && formData.append('amount.surcharge', receivablePayment.amount!.surcharge!.toString());
        receivablePayment.amount!.interest! !== undefined && formData.append('amount.interest', receivablePayment.amount!.interest!.toString());
        receivablePayment.amount!.vat! !== undefined && formData.append('amount.vat', receivablePayment.amount!.vat!.toString());
        receivablePayment.amount!.vatRate! !== undefined && formData.append('amount.vatRate', receivablePayment.amount!.vatRate!.toString());
        receivablePayment.amount!.total! !== undefined && formData.append('amount.total', receivablePayment.amount!.total!.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a receivablePayment.
     * @param receivablePayment - the receivable payment
     */
    private loadDates(receivablePayment: ReceivablePayment) {
        receivablePayment.audit!.created = dateService.transformDate(receivablePayment.audit!.created)!;
        receivablePayment.audit!.updated = dateService.transformDate(receivablePayment.audit!.updated)!;
    }
}

const receivablePaymentApi: ReceivablePaymentApi = new ReceivablePaymentApi();
export default receivablePaymentApi;
