import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import CustomContext from '../../contexts/CustomContext';

/**
 * This component represents a protected route. It redirect to the home page if the user is not authorized to view a route.
 * @param props - the route props
 */
const DefaultRoute: React.FC = () => {
    const context = useContext(CustomContext);
    const { auth } = context;

    if (auth && auth.initialized && auth.authorities.includes('ROLE_ADMIN')) {
        return <Navigate to={'/councils'} />;
    } else {
        return <Navigate to={`/home`} />;
    }
};
export default DefaultRoute;
