import Icon, { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Card, Col, Form, Input, Row, Space, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import councilApi from '../../../apis/CouncilApi';
import AddressComponent from '../../../components/AddressComponent/AddressComponent';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../../contexts/CustomContext';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import { Council } from '../../../models/Entities';
import { ReactComponent as AnnouncementsIcon } from '../../../resources/images/announcements-icon.svg';
import { ReactComponent as ReportsIcon } from '../../../resources/images/reports-icon.svg';
import { ReactComponent as SeizureBankIcon } from '../../../resources/images/seizureBank-icon.svg';
import { ReactComponent as CollectivePaymentOrdersIcon } from '../../../resources/images/collectivePaymentOrders-icon.svg';
import { ReactComponent as PaymentOrdersIcon } from '../../../resources/images/paymentOrders-icon.svg';
import { ReactComponent as ReceivablesIcon } from '../../../resources/images/receivables-icon.svg';
import { ReactComponent as SeizureIncomeIcon } from '../../../resources/images/seizureIncome-icon.svg';
import { ReactComponent as SeizureIncomeSignsIcon } from '../../../resources/images/seizureIncomeSigns-icon.svg';
import { ReactComponent as TaxpayersIcon } from '../../../resources/images/taxpayers-icon.svg';
import { ReactComponent as UsersIcon } from '../../../resources/images/users-icon2.svg';
import { ReactComponent as SettingsIcon } from '../../../resources/images/settings-icon.svg';
import { ReactComponent as ReceiptsIcon } from '../../../resources/images/receipts-icon.svg';
import alertService from '../../../services/AlertService';
import rolesService from '../../../services/RolesService';

import styles from './CouncilPage.module.scss';

/**
 * Returns the council page.
 * @returns the council page.
 */
const CouncilPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [desktop] = useResponsiveLayout();
    const params = useParams<ParamsType>();
    const { auth } = useContext(CustomContext);
    const councilContext = useContext(CustomCouncilContext);
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [council, setCouncil] = useState<Council>();
    const { modal } = App.useApp();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                // load council
                let council: Council;
                if (councilContext.council && councilContext.council.id) {
                    council = councilContext.council;
                } else {
                    council = {
                        status: 'ENABLED'
                    };
                }
                form.setFieldsValue(council);
                setCouncil(council);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilContext.council, form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedCouncil: Council = Object.assign({}, council, values);
            if (updatedCouncil.id) {
                updatedCouncil = await councilApi.update(updatedCouncil);
            } else {
                updatedCouncil = await councilApi.create(updatedCouncil);
            }
            setCouncil(updatedCouncil);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'council.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting' },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await councilApi.delete(council?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/councils');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    return (
        <LayoutComponent
            title={<FormattedMessage id="councils.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name }
            ]}
            council={council}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={<FormattedMessage id="council.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} disabled={!isAdmin} />
                        </Form.Item>
                    </Col>
                </Row>
                <CouncilMenu council={council} />
                {isAdmin && (
                    <Row gutter={[28, 0]}>
                        <Col span={24} lg={12}>
                            <Form.Item
                                label={<FormattedMessage id="council.identifier" />}
                                name="identifier"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    { pattern: /^[P]{1}\d{7}(?:[A-Z0-9])/, message: <FormattedMessage id="status.council.identifier.invalid" /> }
                                ]}
                            >
                                <Input size="large" maxLength={9} />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={6}>
                            <Form.Item
                                label={<FormattedMessage id="council.issuer" />}
                                name="issuerId"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input size="large" maxLength={9} />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={6}>
                            <Form.Item label={<FormattedMessage id="council.cpr" />} name="cpr">
                                <Input size="large" maxLength={9} />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {isAdmin && <AddressComponent field="address" />}
                {isAdmin && (
                    <Row gutter={[28, 0]} className={styles.buttons}>
                        <Col span={24}>
                            <Space>
                                <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                                {council && council.id && (
                                    <Button type="primary" danger size="large" onClick={remove} icon={<DeleteOutlined />}>
                                        {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                                    </Button>
                                )}
                                <Link to="/councils">
                                    <Button size="large" icon={<ArrowLeftOutlined />}>
                                        {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                    </Button>
                                </Link>
                            </Space>
                        </Col>
                    </Row>
                )}
            </Form>
        </LayoutComponent>
    );
};
export default CouncilPage;
type ParamsType = { id: string };

/**
 * Returns the council menu.
 * @returns the council menu.
 */
const CouncilMenu: React.FC<CouncilMenuProps> = (props) => {
    const { council } = props;

    /*** HOOKS ***/

    const { auth } = useContext(CustomContext);

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const isCouncilAdmin = rolesService.hasAnyRole(auth, ['ROLE_COUNCIL_ADMIN']);

    if (isAdmin) {
        return (
            <div className={`${styles.menu} menu`}>
                <Row gutter={[14, 14]}>
                    <Col xs={24} md={6}>
                        <Link to={`/councils/${council?.id}/taxpayers`} className={council && council.id ? undefined : styles.inactive}>
                            <Card className={styles.item}>
                                <Card.Meta
                                    avatar={<Icon component={TaxpayersIcon} className={styles.icon} />}
                                    title={
                                        <p className={styles.title}>
                                            <FormattedMessage id="council.taxpayers" />
                                        </p>
                                    }
                                />
                                <div className={styles.links}>
                                    <p>
                                        <Link to={`/councils/${council?.id}/taxpayers`} className={council && council.id ? undefined : styles.inactive}>
                                            <FormattedMessage id="council.taxpayers" />
                                        </Link>
                                    </p>
                                    <p>
                                        <Link to={`/councils/${council?.id}/payment-letters`} className={council && council.id ? undefined : styles.inactive}>
                                            <FormattedMessage id="council.paymentLetters" />
                                        </Link>
                                    </p>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={24} md={6}>
                        <Card className={styles.item}>
                            <Card.Meta
                                avatar={<Icon component={ReceiptsIcon} className={styles.icon} />}
                                title={
                                    <p className={styles.title}>
                                        <FormattedMessage id="council.voluntaryReceivables" />
                                    </p>
                                }
                            />
                            <div className={styles.links}>
                                <p>
                                    <Link to={`/councils/${council?.id}/voluntary-receivables`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.voluntaryReceivables" />
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to={`/councils/${council?.id}/census-urban-properties`}
                                        className={council && council.id ? undefined : styles.inactive}
                                    >
                                        <FormattedMessage id="council.censusUrbanProperties" />
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to={`/councils/${council?.id}/census-compensation-boards`}
                                        className={council && council.id ? undefined : styles.inactive}
                                    >
                                        <FormattedMessage id="council.compensationBoards" />
                                    </Link>
                                </p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={6}>
                        <Card className={styles.item}>
                            <Card.Meta
                                avatar={<Icon component={ReceivablesIcon} className={styles.icon} />}
                                title={
                                    <p className={styles.title}>
                                        <FormattedMessage id="council.receivables" />
                                    </p>
                                }
                            />

                            <div className={styles.links}>
                                <p>
                                    <Link to={`/councils/${council?.id}/receivables`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.receivables" />
                                    </Link>
                                </p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={6}>
                        <Card className={styles.item}>
                            <Card.Meta
                                avatar={<Icon component={SettingsIcon} className={styles.icon} />}
                                title={
                                    <p className={styles.title}>
                                        <FormattedMessage id="council.settings" />
                                    </p>
                                }
                            />

                            <div className={styles.links}>
                                <p>
                                    <Link to={`/councils/${council?.id}/users`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.users" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/banks`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.bankBranches" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/collection-accounts`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.collectionAccounts" />
                                    </Link>
                                </p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={6}>
                        <Card className={styles.item}>
                            <Card.Meta
                                avatar={<Icon component={PaymentOrdersIcon} className={styles.icon} />}
                                title={
                                    <p className={styles.title}>
                                        <FormattedMessage id="council.apremio.documents" />
                                    </p>
                                }
                            />

                            <div className={styles.links}>
                                <p>
                                    <Link to={`/councils/${council?.id}/payment-order-signs`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.paymentOrderSigns" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/payment-orders`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.paymentOrders" />
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to={`/councils/${council?.id}/payment-order-unofficials`}
                                        className={council && council.id ? undefined : styles.inactive}
                                    >
                                        <FormattedMessage id="council.paymentOrderUnofficials" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/announcements`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.announcements" />
                                    </Link>
                                </p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={6}>
                        <Card className={styles.item}>
                            <Card.Meta
                                avatar={<Icon component={SeizureBankIcon} className={styles.icon} />}
                                title={
                                    <p className={styles.title}>
                                        <FormattedMessage id="council.seizureBank.documents" />
                                    </p>
                                }
                            />

                            <div className={styles.links}>
                                <p>
                                    <Link
                                        to={`/councils/${council?.id}/seizure-bank-info-request`}
                                        className={council && council.id ? undefined : styles.inactive}
                                    >
                                        <FormattedMessage id="council.seizureBankInfoRequest" />
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to={`/councils/${council?.id}/seizure-bank-info-response`}
                                        className={council && council.id ? undefined : styles.inactive}
                                    >
                                        <FormattedMessage id="council.seizureBankInfoResponse" />
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to={`/councils/${council?.id}/seizure-bank-amount-request`}
                                        className={council && council.id ? undefined : styles.inactive}
                                    >
                                        <FormattedMessage id="council.seizureBankAmountRequest" />
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to={`/councils/${council?.id}/seizure-bank-amount-response`}
                                        className={council && council.id ? undefined : styles.inactive}
                                    >
                                        <FormattedMessage id="council.seizureBankAmountResponse" />
                                    </Link>
                                </p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={6}>
                        <Card className={styles.item}>
                            <Card.Meta
                                avatar={<Icon component={SeizureIncomeIcon} className={styles.icon} />}
                                title={
                                    <p className={styles.title}>
                                        <FormattedMessage id="council.seizureIncome.documents" />
                                    </p>
                                }
                            />

                            <div className={styles.links}>
                                <p>
                                    <Link to={`/councils/${council?.id}/seizure-incomes`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.seizureIncomes" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/seizure-income-signs`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.seizureIncomeSigns" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/seizure-income-orders`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.seizureIncomeOrders" />
                                    </Link>
                                </p>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} md={6}>
                        <Card className={styles.item}>
                            <Card.Meta
                                avatar={<Icon component={ReportsIcon} className={styles.icon} />}
                                title={
                                    <p className={styles.title}>
                                        <FormattedMessage id="council.reports" />
                                    </p>
                                }
                            />

                            <div className={styles.links}>
                                <p>
                                    <Link to={`/councils/${council?.id}/billing`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.billing" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/statistics`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.statistics" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/councils/${council?.id}/invoices`} className={council && council.id ? undefined : styles.inactive}>
                                        <FormattedMessage id="council.invoices" />
                                    </Link>
                                </p>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    } else {
        return (
            <div className={`${styles.menu} menu`}>
                <Row gutter={[14, 14]}>
                    {isCouncilAdmin && (
                        <Col xs={24} md={8}>
                            <Link to={`/councils/${council?.id}/users`} className={council && council.id ? undefined : styles.inactive}>
                                <Card className={styles.item}>
                                    <Card.Meta
                                        avatar={<Icon component={UsersIcon} className={styles.icon} />}
                                        title={<FormattedMessage id="council.users" />}
                                        description={<FormattedMessage id="council.users.desc" />}
                                    />
                                </Card>
                            </Link>
                        </Col>
                    )}
                    <Col xs={24} md={8}>
                        <Link to={`/councils/${council?.id}/payment-order-signs`} className={council && council.id ? undefined : styles.inactive}>
                            <Card className={styles.item}>
                                <Card.Meta
                                    avatar={<Icon component={CollectivePaymentOrdersIcon} className={styles.icon} />}
                                    title={<FormattedMessage id="council.paymentOrderSigns" />}
                                    description={<FormattedMessage id="council.paymentOrderSigns.desc" />}
                                />
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={24} md={8}>
                        <Link to={`/councils/${council?.id}/announcements`} className={council && council.id ? undefined : styles.inactive}>
                            <Card className={styles.item}>
                                <Card.Meta
                                    avatar={<Icon component={AnnouncementsIcon} className={styles.icon} />}
                                    title={<FormattedMessage id="council.announcements" />}
                                    description={<FormattedMessage id="council.announcements.desc" />}
                                />
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <Row gutter={[14, 14]} className={styles.group}>
                    <Col xs={24} md={8}>
                        <Link to={`/councils/${council?.id}/seizure-incomes`} className={council && council.id ? undefined : styles.inactive}>
                            <Card className={styles.item}>
                                <Card.Meta
                                    avatar={<Icon component={SeizureIncomeIcon} className={styles.icon} />}
                                    title={<FormattedMessage id="council.seizureIncomes" />}
                                    description={<FormattedMessage id="council.seizureIncomes.desc" />}
                                />
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={24} md={8}>
                        <Link to={`/councils/${council?.id}/seizure-income-signs`} className={council && council.id ? undefined : styles.inactive}>
                            <Card className={styles.item}>
                                <Card.Meta
                                    avatar={<Icon component={SeizureIncomeSignsIcon} className={styles.icon} />}
                                    title={<FormattedMessage id="council.seizureIncomeSigns" />}
                                    description={<FormattedMessage id="council.seizureIncomeSigns.desc" />}
                                />
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }
};
interface CouncilMenuProps {
    council: Council | undefined;
}
