import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, List, Modal, Row, Upload, UploadFile, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import receivableVoluntaryResetApi from '../../../../../apis/ReceivableVoluntaryResetApi';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import { Receivable, ReceivableVoluntaryReset } from '../../../../../models/Entities';
import alertService from '../../../../../services/AlertService';
import styles from './ReceivableVoluntaryResetModal.module.scss';
import dayjs from 'dayjs';

const ReceivableVoluntaryResetModal = (props: Props) => {
    const { receivable } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [files, setFiles] = useState<UploadFile[]>([]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('saving');
            let receivableVoluntaryReset: ReceivableVoluntaryReset = Object.assign({}, { receivableId: receivable!.id, reset: dayjs() }, values);
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            await receivableVoluntaryResetApi.create(receivableVoluntaryReset, attachment);
            props.onSave();
            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="receivable.voluntaryReset.modal.title" />}
            open={true}
            onCancel={cancel}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{
                loading: loading === 'saving',
                size: 'large',
                htmlType: 'submit'
            }}
            cancelButtonProps={{ size: 'large' }}
            width={1100}
            destroyOnClose
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={(values) => save(values)}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]}>
                <Col span={7}>
                    <Form.Item
                        label={<FormattedMessage id="receivable.voluntaryReset.expired" />}
                        name="expired"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Form.Item
                        name="file"
                        valuePropName="files"
                        label={
                            <>
                                <span>
                                    <FormattedMessage id="receivable.voluntaryReset.modal.upload" />
                                </span>
                            </>
                        }
                        rules={[
                            {
                                validator: validateFile
                            }
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                <List.Item.Meta
                                    avatar={<PaperClipOutlined className={styles.icon} />}
                                    title={file.name}
                                    description={<FileSizeComponent value={file.size} />}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default ReceivableVoluntaryResetModal;

interface Props {
    receivable: Receivable;
    onSave: () => void;
    onCancel: () => void;
}
