import { CaretDownFilled, CreditCardOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomContext from '../../contexts/CustomContext';
import { CouncilTaxpayer, Receivable } from '../../models/Entities';
import receivableService from '../../services/ReceivableService';
import rolesService from '../../services/RolesService';
import PaymentLetterModal from '../PaymentLetterModal/PaymentLetterModal';
import PaymentModal from '../PaymentModal/PaymentModal';
import styles from './CouncilTaxpayerReceivableActionsMenu.module.scss';

const CouncilTaxpayerReceivableActionsMenu = (props: Props) => {
    const { receivables, councilTaxpayer, onAction } = props;

    /*** HOOKS ***/

    const { auth } = useContext(CustomContext);
    const [paymentLetterModalVisible, setPaymentLetterModalVisible] = useState<boolean>(false);
    const [paymentModalVisible, setPaymentModalVisible] = useState<boolean>(false);

    /*** METHODS ***/

    const save = async () => {
        setPaymentLetterModalVisible(false);
        setPaymentModalVisible(false);
        onAction();
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const items: ItemType[] = [
        {
            key: 'payment',
            type: 'group',
            label: (
                <span className={styles.label}>
                    <FormattedMessage id="receivables.actions.header.payment" />
                </span>
            ),
            children: [
                {
                    key: 'payment-letter',
                    icon: <CreditCardOutlined />,
                    onClick: () => setPaymentLetterModalVisible(true),
                    label: <FormattedMessage id="receivables.actions.paymentLetter" />,
                    disabled: !receivableService.isCreatePaymentLetterEnabled(auth, receivables)
                },
                {
                    key: 'payment',
                    icon: <CreditCardOutlined />,
                    onClick: () => setPaymentModalVisible(true),
                    label: <FormattedMessage id="receivables.actions.payment" />,
                    disabled: !receivableService.isCreatePaymentEnabled(auth)
                }
            ].filter((item) => ['payment-letter', 'payment'].includes(item.key) && isAdmin)
        }
    ];

    return (
        <>
            <Dropdown trigger={['click']} placement="bottomRight" menu={{ items }}>
                <Button type="primary" size="large">
                    <FormattedMessage id="button.actions" /> <CaretDownFilled />
                </Button>
            </Dropdown>
            {paymentLetterModalVisible && (
                <PaymentLetterModal
                    councilTaxpayer={councilTaxpayer}
                    receivables={receivables}
                    onCancel={() => setPaymentLetterModalVisible(false)}
                    onSave={save}
                />
            )}
            {paymentModalVisible && <PaymentModal councilTaxpayer={councilTaxpayer} onCancel={() => setPaymentModalVisible(false)} onSave={save} />}
        </>
    );
};

export default CouncilTaxpayerReceivableActionsMenu;

interface Props {
    councilTaxpayer: CouncilTaxpayer;
    receivables: Receivable[];
    onAction: () => Promise<void>;
}
