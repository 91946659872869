import { RollbackOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { useContext, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Receivable, ReceivableInitialization } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableInitializationComponent.module.scss';
import ReceivableVoluntaryResetModal from './ReceivableVoluntaryResetModal/ReceivableVoluntaryResetModal';
import rolesService from '../../../../services/RolesService';
import CustomContext from '../../../../contexts/CustomContext';

const ReceivableInitializationComponent = (props: Props) => {
    const { initialization, receivable, onRevert, readonly } = props;

    /*** HOOKS ***/
    const { auth } = useContext(CustomContext);
    const [receivableVoluntaryResetModal, setReceivableVoluntaryResetModalVisible] = useState<boolean>(false);
    const intl = useIntl();
    const [, setLoading] = useState<'reverting'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const revert = async () => {
        try {
            setLoading('reverting');
            onRevert();
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/
    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const items: DescriptionsProps['items'] = [
        {
            key: 'principal',
            label: <FormattedMessage id="amount.principal" />,
            children: initialization.amount?.principal !== undefined && (
                <FormattedNumber
                    value={initialization.amount.principal}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'surcharge',
            label: (
                <>
                    <FormattedMessage id="amount.surcharge" /> ({initialization.penalty * 100}%)
                </>
            ),
            children: initialization.amount?.surcharge !== undefined && (
                <FormattedNumber
                    value={initialization.amount.surcharge}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'vat',
            label: (
                <>
                    <FormattedMessage id="amount.vat" />
                </>
            ),
            children: initialization.amount?.vat !== undefined && (
                <FormattedNumber
                    value={initialization.amount.vat}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },

        {
            key: 'total',
            label: <FormattedMessage id="amount.total" />,
            children: initialization.amount?.total !== undefined && (
                <strong>
                    <FormattedNumber
                        value={initialization.amount.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>
            {isAdmin && !readonly && (
                <Row gutter={[28, 0]} className={styles.buttons}>
                    <Col span={24}>
                        <Button icon={<RollbackOutlined />} onClick={() => setReceivableVoluntaryResetModalVisible(true)}>
                            <FormattedMessage id="button.revert" tagName="span" />
                        </Button>
                    </Col>
                </Row>
            )}
            {receivableVoluntaryResetModal && (
                <ReceivableVoluntaryResetModal receivable={receivable} onSave={revert} onCancel={() => setReceivableVoluntaryResetModalVisible(false)} />
            )}
        </>
    );
};

export default ReceivableInitializationComponent;

interface Props {
    initialization: ReceivableInitialization;
    receivable: Receivable;
    onRevert: () => void;
    readonly: boolean;
}
