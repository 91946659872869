import { Col, DatePicker, Form, Input, Modal, Row, Select, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import councilTaxpayerRemarkApi from '../../../../../apis/CouncilTaxpayerRemarkApi';
import ConstantLabel from '../../../../../components/ConstantLabel/ConstantLabel';
import CustomCouncilContext from '../../../../../contexts/CustomCouncilContext';
import CustomCouncilTaxpayerContext from '../../../../../contexts/CustomCouncilTaxpayerContext';
import { CouncilTaxpayerRemark } from '../../../../../models/Entities';
import { CouncilTaxpayerRemarkType, councilTaxpayerRemarkTypes } from '../../../../../models/Types';
import alertService from '../../../../../services/AlertService';
import styles from './CouncilTaxpayerRemarkModal.module.scss';

/**
 * Returns the council taxpayer remark modal.
 * @returns the council taxpayer remark modal.
 */
const CouncilTaxpayerRemarkModal: React.FC<PropsType> = (props) => {
    const { id } = props;
    /***HOOKS***/
    const intl = useIntl();
    const [form] = Form.useForm();
    const { council } = useContext(CustomCouncilContext);
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting' | 'saving'>();
    const [councilTaxpayerRemark, setCouncilTaxpayerRemark] = useState<CouncilTaxpayerRemark>();
    const { TextArea } = Input;

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('initializing');
                    let councilTaxpayerRemark: CouncilTaxpayerRemark;
                    if (id === undefined) {
                        councilTaxpayerRemark = { councilTaxpayerId: councilTaxpayer!.id };
                    } else {
                        councilTaxpayerRemark = await councilTaxpayerRemarkApi.get(id!);
                    }
                    form.setFieldsValue(councilTaxpayerRemark);
                    setCouncilTaxpayerRemark(councilTaxpayerRemark);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, councilTaxpayer, form, intl, id]);

    /*** METHODS ***/

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedCouncilTaxpayerRemark: CouncilTaxpayerRemark = Object.assign({}, councilTaxpayerRemark, values);
            if (councilTaxpayerRemark?.id) {
                updatedCouncilTaxpayerRemark = await councilTaxpayerRemarkApi.update(updatedCouncilTaxpayerRemark);
            } else {
                updatedCouncilTaxpayerRemark = await councilTaxpayerRemarkApi.create(updatedCouncilTaxpayerRemark);
            }
            setCouncilTaxpayerRemark(updatedCouncilTaxpayerRemark);

            message.success(intl.formatMessage({ id: 'status.saved' }));
            props.onSave();
        } catch (error) {
            alertService.displayError(error, intl, [{ status: 409, message: 'user.email.duplicate' }]);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const remarkOptions = councilTaxpayerRemarkTypes.map((rt: CouncilTaxpayerRemarkType) => ({
        value: rt,
        label: <ConstantLabel value={rt} prefix="councilTaxpayerRemarks.type." />
    }));

    return (
        <Modal
            title={<FormattedMessage id="councilTaxpayerRemark.modal.title" />}
            open={true}
            onCancel={cancel}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{
                loading: loading === 'saving',
                size: 'large',
                htmlType: 'submit'
            }}
            cancelButtonProps={{ size: 'large' }}
            width={1100}
            destroyOnClose
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={(values) => save(values)}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]}>
                <Col span={12} lg={8}>
                    <Form.Item name="remarkType">
                        <Select
                            placeholder={intl.formatMessage({
                                id: 'councilTaxpayerRemarks.filter.type'
                            })}
                            size="large"
                            className={styles.select}
                            allowClear
                            onChange={form.submit}
                            options={remarkOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col span={12} lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="councilTaxpayerRemark.published" />}
                        name="published"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <DatePicker size="large" className={styles.date} />
                    </Form.Item>
                </Col>
                <Col span={24} lg={18}>
                    <Form.Item
                        label={<FormattedMessage id="councilTaxpayerRemark.content" />}
                        name="content"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <TextArea rows={4} maxLength={800} />
                    </Form.Item>
                </Col>
            </Row>
        </Modal>
    );
};

export default CouncilTaxpayerRemarkModal;
type PropsType = { id: number | undefined; onSave: () => void; onCancel: () => void };
