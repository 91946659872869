import { Image, Layout, Row, Space } from 'antd';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import taxfenIcon from '../../resources/images/taxfen-icon.svg';
import taxfenTitleIcon from '../../resources/images/taxfen-title-icon.svg';
import styles from './PublicLayoutComponent.module.scss';

const { Header, Footer } = Layout;

const PublicLayoutComponent: React.FC<Props> = (props) => {
    const { children } = props;

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <Layout className={styles.layout}>
            <Header className={styles.header}>
                <div className={styles.title}>
                    <Link to="/home" aria-label="Go to home page">
                        <Image src={taxfenIcon} preview={false} className={styles.logo} alt="" aria-hidden="true" />
                        <Image src={taxfenTitleIcon} preview={false} className={styles.logoTitle} alt="" aria-hidden="true" />
                    </Link>
                </div>
            </Header>
            <Row className={styles.content} justify="center">
                {children}
            </Row>
            <Footer className={styles.footer}>
                <div>
                    <Space className={styles.links} size="large">
                        <Link to="/legal-notice" className={styles.link}>
                            <FormattedMessage id="common.legalNotice" />
                        </Link>
                        <Link to="/privacy-policy" className={styles.link}>
                            <FormattedMessage id="common.privacyPolicy" />
                        </Link>
                    </Space>
                </div>
                <div className={styles.copyright}>
                    <a href="https://walfen.com" target="_blank" rel="noreferrer">
                        ©2025 Walfen Technologies S.L.
                    </a>
                </div>
            </Footer>
        </Layout>
    );
};
export default PublicLayoutComponent;

interface Props extends PropsWithChildren {}
