import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, message, Popconfirm, Row, Select, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import censusCompensationBoardApi from '../../../apis/CensusCompensationBoardApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../../contexts/CustomContext';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import { BreadcrumbItem } from '../../../models/Elements';
import { CensusCompensationBoard, CensusCompensationBoardPlot, Council } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import rolesService from '../../../services/RolesService';
import { Table } from 'antd/lib';
import { ColumnsType } from 'antd/lib/table';
import styles from './CensusCompensationBoardPage.module.scss';
import CensusCompensationBoardPlotModal from '../../../components/CensusCompensationBoardPlotModal/CensusCompensationBoardPlotModal';
import stringService from '../../../services/StringService';
import { provinces } from 'all-spanish-cities';
import { Province } from 'all-spanish-cities/dist/types';

/**
 * Returns the census compensation board page.
 * @returns the census compensation board page.
 */
const CensusCompensationBoardPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [form] = Form.useForm();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'refreshing' | 'deleting'>();
    const [censusCompensationBoard, setCensusCompensationBoard] = useState<CensusCompensationBoard>();
    const [censusCompensationBoardPlot, setCensusCompensationBoardPlot] = useState<CensusCompensationBoardPlot>();
    const [desktop] = useResponsiveLayout();

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('initializing');
                    const censusCompensationBoard: CensusCompensationBoard = await censusCompensationBoardApi.get(+params.id!);
                    form.setFieldsValue(censusCompensationBoard);
                    setCensusCompensationBoard(censusCompensationBoard);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedCensusCompensationBoard: CensusCompensationBoard = Object.assign({}, censusCompensationBoard, values);
            updatedCensusCompensationBoard = await censusCompensationBoardApi.update(updatedCensusCompensationBoard);
            setCensusCompensationBoard(updatedCensusCompensationBoard);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        try {
            if (censusCompensationBoard && censusCompensationBoard.id) {
                setLoading('deleting');
                await censusCompensationBoardApi.delete(censusCompensationBoard.id);
                message.success(intl.formatMessage({ id: 'status.deleted' }));
                navigate(`/councils/${council.id}/census-compensation-boards`);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showModal = (censusCompensationBoardPlot: CensusCompensationBoardPlot) => {
        setCensusCompensationBoardPlot(censusCompensationBoardPlot);
    };

    const hideModal = () => {
        setCensusCompensationBoardPlot(undefined);
    };

    const updateCensusCompensationBoardPlot = (censusCompensationBoardPlot: CensusCompensationBoardPlot) => {
        const updatedCensusCompensationBoard: CensusCompensationBoard = Object.assign({}, censusCompensationBoard);
        updatedCensusCompensationBoard.plots = updatedCensusCompensationBoard.plots.map((p) => {
            return p.id === censusCompensationBoardPlot.id ? censusCompensationBoardPlot : p;
        });
        setCensusCompensationBoard(updatedCensusCompensationBoard);

        hideModal();
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const items = censusCompensationBoard && censusCompensationBoard.plots ? censusCompensationBoard.plots : [];
    const sortedProvinces: Province[] = provinces().sort((a, b) => stringService.sort(a.name, b.name));
    const provincesOptions = sortedProvinces.map((p) => ({ value: p.code, label: p.name }));

    const columns: ColumnsType<CensusCompensationBoardPlot> = [
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.identifier" />,
            dataIndex: ['identifier'],
            key: 'plotIdentifier',
            width: 80,
            sorter: false,
            defaultSortOrder: 'ascend',
            render: (value: number, censusCompensationBoardPlot: CensusCompensationBoardPlot) => (
                <Button type="link" onClick={() => showModal(censusCompensationBoardPlot)}>
                    {value}
                </Button>
            )
        },
        {
            title: <FormattedMessage id="censusCompensationBoard.plots.rights" />,
            dataIndex: 'rightsPercentage',
            key: 'rightsPercentage',
            align: 'right',
            width: 80,
            render: (value: number, censusCompensationBoardPlot: CensusCompensationBoardPlot) => (
                <Button type="link" onClick={() => showModal(censusCompensationBoardPlot)}>
                    <FormattedNumber value={value / 100.0} minimumFractionDigits={2} maximumFractionDigits={2} style={'percent' as any} />
                </Button>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilBanks.title" />}
            menu="councils"
            path={PathComponent(council, censusCompensationBoard!)}
            council={council}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.reference" />}
                            name="reference"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.taxpayer.identifier" />}
                            name={['taxpayer', 'identifier']}
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^\d{8}(?![IÑOU])[A-Z]$|^[XYZ]{1}\d{7}(?![IÑOU])[A-Z]$|^[ABCDEFGHJNPQRSUVW]{1}\d{7}(?:[A-Z0-9])/,
                                    message: <FormattedMessage id="status.taxpayer.identifier.invalid" />
                                }
                            ]}
                        >
                            <Input size="large" maxLength={9} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.taxpayer.firstName" />}
                            name={['taxpayer', 'firstName']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label={<FormattedMessage id="censusCompensationBoard.taxpayer.lastName" />} name={['taxpayer', 'lastName']}>
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label={<FormattedMessage id="censusCompensationBoard.taxpayer.secondLastName" />} name={['taxpayer', 'secondLastName']}>
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={<FormattedMessage id="censusCompensationBoard.taxpayer.address.street" />} name={['taxpayer', 'address', 'street']}>
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label={<FormattedMessage id="censusCompensationBoard.taxpayer.address.city" />} name={['taxpayer', 'address', 'city']}>
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={2}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.taxpayer.address.postcode" />}
                            name={['taxpayer', 'address', 'postcode']}
                        >
                            <InputNumber size="large" maxLength={5} controls={false} className="input-number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.taxpayer.address.province" />}
                            name={['taxpayer', 'address', 'province']}
                        >
                            <Select size="large" allowClear showSearch filterOption={stringService.filterOptions} options={[...provincesOptions]} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label={<FormattedMessage id="censusCompensationBoard.taxpayer.address.phone" />} name={['taxpayer', 'address', 'phone']}>
                            <InputNumber size="large" controls={false} className="input-number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label={<FormattedMessage id="censusCompensationBoard.taxpayer.address.email" />} name={['taxpayer', 'address', 'email']}>
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.principal" />}
                            name="principal"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={2}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.vatRate" />}
                            name="vatRate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item
                            label={<FormattedMessage id="censusCompensationBoard.vat" />}
                            name="vat"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" min={0} max={100} controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Table
                            dataSource={items}
                            columns={columns}
                            rowKey="id"
                            showSorterTooltip={false}
                            pagination={false}
                            loading={loading === 'loading'}
                            className={`table ${styles.table}`}
                        />
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            {isAdmin && (
                                <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                            )}
                            {censusCompensationBoard?.id !== undefined && isAdmin && (
                                <Popconfirm title={intl.formatMessage({ id: 'censusCompensationBoardPage.deleteModal.title' })}>
                                    <Button type="primary" danger size="large" onClick={remove} icon={<DeleteOutlined />}>
                                        {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                                    </Button>
                                </Popconfirm>
                            )}
                            <Link to={`/councils/${council.id}/census-compensation-boards`}>
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
            {censusCompensationBoardPlot && (
                <CensusCompensationBoardPlotModal
                    censusCompensationBoardPlot={censusCompensationBoardPlot}
                    onSave={updateCensusCompensationBoardPlot}
                    onCancel={hideModal}
                />
            )}
        </LayoutComponent>
    );
};

export default CensusCompensationBoardPage;
type ParamsType = { id: string };

/**
 * Returns the path of the census compensation board page.
 * @param council the council
 * @returns  the path
 */
const PathComponent = (council: Council, censusCompensationBoard: CensusCompensationBoard): BreadcrumbItem[] => {
    return [
        { path: '/councils', name: <FormattedMessage id="councils.title" /> },
        { path: `/councils/${council.id}`, name: council.name },
        { path: `/councils/${council.id}/census-compensation-boards`, name: <FormattedMessage id="censusCompensationBoards.title" /> },
        { path: `/councils/${council.id}/census-compensation-boards/${censusCompensationBoard?.id}`, name: censusCompensationBoard?.id }
    ];
};
