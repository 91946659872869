class Configuration {
    // domains of environments (hardcoded - react doesn't allow changes on runtime)
    readonly testDomain = 'test.taxfen.com';
    readonly stageDomain = 'stage.taxfen.com';
    readonly productionDomain = 'taxfen.com';

    // configuration attributes
    coreApiUrl: string | undefined;
    awsAuth: any | undefined;
    claveProvider: string | undefined;

    constructor() {
        // set up configuration attributes based on environment
        if (window.location.hostname.endsWith(this.testDomain)) {
        } else if (window.location.hostname.endsWith(this.stageDomain)) {
        } else if (window.location.hostname.endsWith(this.productionDomain)) {
            this.coreApiUrl = 'https://core.api.taxfen.com';
            this.awsAuth = {
                Auth: {
                    region: 'eu-central-1',
                    userPoolId: 'eu-central-1_4RoDZ91RK',
                    userPoolWebClientId: '13uf60kebij2mqktf3vhe7ger6',
                    authenticationFlowType: 'USER_SRP_AUTH',
                    oauth: {
                        domain: 'taxfen-dev.auth.eu-central-1.amazoncognito.com',
                        redirectSignIn: `https://${this.productionDomain}`,
                        scope: ['email', 'openid', 'profile'],
                        responseType: 'code'
                    }
                }
            };
            this.claveProvider = 'ProductionSeamlMockClaveD1CA43DC';
            // } else {
            //     this.coreApiUrl = 'https://core.api.taxfen.com';
            //     this.awsAuth = {
            //         Auth: {
            //             region: 'eu-central-1',
            //             userPoolId: 'eu-central-1_4RoDZ91RK',
            //             userPoolWebClientId: '13uf60kebij2mqktf3vhe7ger6',
            //             authenticationFlowType: 'USER_SRP_AUTH'
            //         }
            //     };
            // }
        } else {
            this.coreApiUrl = 'http://localhost:8010';
            this.awsAuth = {
                Auth: {
                    region: 'eu-central-1',
                    userPoolId: 'eu-central-1_RUMeZEkZL',
                    userPoolWebClientId: '3nk1dmlrisnooerngne5j7pk31',
                    authenticationFlowType: 'USER_SRP_AUTH',
                    oauth: {
                        domain: 'taxfen-dev.auth.eu-central-1.amazoncognito.com',
                        redirectSignIn: 'http://localhost:3000',
                        scope: ['email', 'openid', 'profile'],
                        responseType: 'code'
                    }
                }
            };
            this.claveProvider = 'DevStackDeveamlMockClave9F39CA97';
        }
    }
}
const configuration: Configuration = new Configuration();
export default configuration;
