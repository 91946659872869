import { Page } from '../models/Elements';
import { CensusCompensationBoard, CensusDocument, CensusTransfer, ReceivableVoluntaryRegistrationCompensationBoard } from '../models/Entities';
import { StatusType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CensusCompensationBoardApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string,
        transferred?: boolean,
        status?: StatusType
    ): Promise<Page<CensusCompensationBoard>> => {
        const response = await adminApiAxios.get<Page<CensusCompensationBoard>>(`/census-compensation-boards`, {
            params: { page, size, sortField, sortOrder, councilId, searchText, transferred, status }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<CensusCompensationBoard> => {
        const response = await adminApiAxios.get<CensusCompensationBoard>(`/census-compensation-boards/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (censusCompensationBoard: CensusCompensationBoard): Promise<CensusCompensationBoard> => {
        const response = await adminApiAxios.post<CensusCompensationBoard>(`/census-compensation-boards`, censusCompensationBoard);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (censusCompensationBoard: CensusCompensationBoard): Promise<CensusCompensationBoard> => {
        const response = await adminApiAxios.put<CensusCompensationBoard>(`/census-compensation-boards/${censusCompensationBoard.id}`, censusCompensationBoard);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/census-compensation-boards/${id}`);

        return response.data;
    };

    createResults = async (censusDocument: CensusDocument, file?: any): Promise<CensusCompensationBoard[]> => {
        const formData: FormData = this.buildFormData(censusDocument, file);
        const response = await adminApiAxios.post<CensusCompensationBoard[]>(`/census-compensation-boards/results`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        response.data.forEach((item) => this.loadDates(item));

        return response.data;
    };

    transfer = async (censusTransfer: CensusTransfer): Promise<ReceivableVoluntaryRegistrationCompensationBoard[]> => {
        const response = await adminApiAxios.post<ReceivableVoluntaryRegistrationCompensationBoard[]>(`/census-compensation-boards/transfers`, censusTransfer);
        response.data.forEach((item) => this.loadReceivableVoluntaryRegistrationCompensationBoardDates(item));

        return response.data;
    };

    /**
     * Builds a form data from a census compensation board document.
     * @param censusDocument the census document
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (censusDocument: CensusDocument, file?: File) => {
        const formData: FormData = new FormData();
        censusDocument.id && formData.append('id', censusDocument.id.toString());
        censusDocument.councilId && formData.append('councilId', censusDocument.councilId.toString());
        censusDocument.tax && formData.append('tax', censusDocument.tax.toString());
        censusDocument.index && formData.append('index', censusDocument.index.toString());
        censusDocument.year && formData.append('year', censusDocument.year.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a census compensation board.
     * @param censusCompensationBoard - the censusCompensationBoard
     */
    private loadDates(censusCompensationBoard: CensusCompensationBoard) {
        censusCompensationBoard.audit!.created = dateService.transformDate(censusCompensationBoard.audit!.created)!;
        censusCompensationBoard.audit!.updated = dateService.transformDate(censusCompensationBoard.audit!.updated)!;
    }

    /**
     * Loads the dates of a receivable Voluntary Registration Compensation Board.
     * @param receivableVoluntaryRegistrationCompensationBoard - the receivableVoluntaryRegistrationCompensationBoard
     */
    private loadReceivableVoluntaryRegistrationCompensationBoardDates(
        receivableVoluntaryRegistrationCompensationBoard: ReceivableVoluntaryRegistrationCompensationBoard
    ) {
        receivableVoluntaryRegistrationCompensationBoard.audit!.created = dateService.transformDate(
            receivableVoluntaryRegistrationCompensationBoard.audit!.created
        )!;
        receivableVoluntaryRegistrationCompensationBoard.audit!.updated = dateService.transformDate(
            receivableVoluntaryRegistrationCompensationBoard.audit!.updated
        )!;
    }
}

const censusCompensationBoardApi: CensusCompensationBoardApi = new CensusCompensationBoardApi();
export default censusCompensationBoardApi;
