import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select, Space, Tooltip } from 'antd';
import { UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import censusDocumentApi from '../../../apis/CensusDocumentApi';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Census, CensusCompensationBoard, CensusDocument, CensusUrbanProperty, Council } from '../../../models/Entities';
import { TaxType } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import stringService from '../../../services/StringService';
import styles from './CensusDocumentsSummaryComponent.module.scss';
import CensusUrbanPropertyTableComponent from './CensusUrbanPropertyTableComponent/CensusUrbanPropertyTableComponent';
import CensusCompensationBoardTableComponent from './CensusCompensationBoardTableComponent/CensusCompensationBoardTableComponent';

/**
 * Returns the census document summary component.
 * @returns the census document summary component.
 */
const CensusDocumentsSummaryComponent: React.FC<Props> = (props) => {
    const { onImport, onBack, censuses, council, tax, file } = props;

    /***HOOKS***/
    const [form] = Form.useForm();
    const [desktop] = useResponsiveLayout();
    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const [censusDocuments, setCensusDocuments] = useState<CensusDocument[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const censusDocumentsPage = await censusDocumentApi.list(0, 1000, 'id', false, council.id!);
                const censusDocuments = censusDocumentsPage.content
                    .filter((item, index, self) => self.findIndex((i) => i.identifier === item.identifier) === index)
                    .sort((a, b) => stringService.sort(b.identifier, a.identifier));
                setCensusDocuments(censusDocuments);
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [council.id, intl]);

    /***METHODS***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            const selectedCensusDocument = censusDocuments.find((item) => item.id === values.documentId);
            let censusDocument: CensusDocument = {
                councilId: council.id!,
                identifier: selectedCensusDocument?.identifier,
                year: selectedCensusDocument?.year,
                index: selectedCensusDocument?.index,
                tax: tax
            };
            censusDocument = await censusDocumentApi.create(censusDocument, file);

            onImport(censusDocument);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /***VISUAL***/

    const censusDocumentOptions = censusDocuments.map((cd) => ({ value: cd.id, label: cd.identifier }));
    const errors = censuses.map((c) => c.errors.length).reduce((partialSum, a) => partialSum + a, 0);

    return (
        <>
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} lg={9}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperies.import.censusDocumentIdentifier" />}
                            name="documentId"
                            extra={<FormattedMessage id="censusUrbanProperies.import.censusDocumentIdentifier.desc" />}
                            className={styles.select}
                        >
                            <Select size="large" allowClear showSearch options={[...censusDocumentOptions]} />
                        </Form.Item>
                    </Col>
                </Row>
                {tax === 'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA' && <CensusUrbanPropertyTableComponent censuses={censuses as CensusUrbanProperty[]} />}
                {tax === 'JUNTA_DE_COMPENSACION' && <CensusCompensationBoardTableComponent censuses={censuses as CensusCompensationBoard[]} />}
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={24}>
                        <Space>
                            {errors > 0 && (
                                <Tooltip title={<FormattedMessage id="receivables.import.tooltip.error.title" />}>
                                    <Button type="primary" size="large" icon={<SaveOutlined />} onClick={save} disabled>
                                        <FormattedMessage id="button.import" tagName="span" />
                                    </Button>
                                </Tooltip>
                            )}
                            {errors === 0 && (
                                <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                    <FormattedMessage id="button.import" tagName="span" />
                                </Button>
                            )}
                            <Button size="large" icon={<ArrowLeftOutlined />} onClick={onBack}>
                                {desktop && <FormattedMessage id="button.back" tagName="span" />}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
export default CensusDocumentsSummaryComponent;

interface Props {
    council: Council;
    tax: TaxType;
    censuses: Census[];
    file: UploadFile | undefined;
    onImport: (censusDocument: CensusDocument) => void;
    onBack: () => void;
}
