import { Col, Result, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CensusDocument, Council } from '../../../models/Entities';
import { TaxType } from '../../../models/Types';
import CensusDocumentsResultButtonComponent from './CensusDocumentsResultButtonComponent/CensusDocumentsResultButtonComponent';

/**
 * Returns the census documents result component.
 * @returns the census documents result component.
 */
const CensusDocumentsResultComponent: React.FC<Props> = (props) => {
    const { council, censusDocument, tax } = props;

    /***VISUAL***/

    return (
        <>
            <Row gutter={[28, 0]}>
                {censusDocument.file?.name && (
                    <Col span={24}>
                        <Result
                            status="403"
                            title={<FormattedMessage id="censusDocuments.import.success" />}
                            subTitle={<FormattedMessage id="censusDocuments.import.success.desc" />}
                            extra={<CensusDocumentsResultButtonComponent tax={tax} council={council} />}
                        />
                    </Col>
                )}
            </Row>
        </>
    );
};
export default CensusDocumentsResultComponent;
interface Props {
    council: Council;
    censusDocument: CensusDocument;
    tax: TaxType;
}
