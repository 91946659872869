import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Council } from '../../../../models/Entities';
import { TaxType } from '../../../../models/Types';

/**
 * Returns the census documents result button component.
 * @returns the census documents result button component.
 */
const CensusDocumentsResultButtonComponent: React.FC<Props> = (props) => {
    const { tax, council } = props;

    /***VISUAL***/

    switch (tax) {
        case 'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA':
            return (
                <Link to={`/councils/${council.id}/census-urban-properties`}>
                    <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
                        <FormattedMessage id="censusDocuments.import.steps.3.back" tagName="span" />
                    </Button>
                </Link>
            );
        case 'JUNTA_DE_COMPENSACION':
            return (
                <Link to={`/councils/${council.id}/census-compensation-boards`}>
                    <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
                        <FormattedMessage id="censusDocuments.import.steps.3.back" tagName="span" />
                    </Button>
                </Link>
            );
        default:
            return (
                <Link to={`/councils/${council.id}`}>
                    <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
                        <FormattedMessage id="button.back" tagName="span" />
                    </Button>
                </Link>
            );
    }
};

export default CensusDocumentsResultButtonComponent;

interface Props {
    tax: TaxType;
    council: Council;
}
