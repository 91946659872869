import { ReceivableVoluntaryReset, ReceivableVoluntaryResetBulk } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class ReceivableVoluntaryResetApi {
    create = async (receivableVoluntaryReset: ReceivableVoluntaryReset, file?: any): Promise<ReceivableVoluntaryReset> => {
        const formData: FormData = this.buildFormData(receivableVoluntaryReset, file);
        const response = await adminApiAxios.post<ReceivableVoluntaryReset>('/receivable-voluntary-resets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    createBulk = async (receivableVoluntaryResetBulk: ReceivableVoluntaryResetBulk, file?: any): Promise<ReceivableVoluntaryResetBulk> => {
        const formData: FormData = this.buildBulkFormData(receivableVoluntaryResetBulk, file);
        const response = await adminApiAxios.post<ReceivableVoluntaryResetBulk>('/receivable-voluntary-resets/bulk', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/receivable-voluntary-resets/${id}/file`, { responseType: 'blob' });

        return response.data;
    };

    private buildFormData = (receivableVoluntaryReset: ReceivableVoluntaryReset, file?: File) => {
        const formData: FormData = new FormData();
        receivableVoluntaryReset.id && formData.append('id', receivableVoluntaryReset.id.toString());
        receivableVoluntaryReset.receivableId && formData.append('receivableId', receivableVoluntaryReset.receivableId.toString());
        receivableVoluntaryReset.state && formData.append('state', receivableVoluntaryReset.state.toString());
        receivableVoluntaryReset.reset && formData.append('reset', receivableVoluntaryReset.reset.format('YYYY-MM-DDThh:mm:ss'));
        receivableVoluntaryReset.expired && formData.append('expired', receivableVoluntaryReset.expired.format('YYYY-MM-DD'));
        receivableVoluntaryReset.audit?.created && formData.append('audit.created', receivableVoluntaryReset.audit.created.format('YYYY-MM-DDThh:mm:ss'));
        receivableVoluntaryReset.audit?.updated && formData.append('audit.updated', receivableVoluntaryReset.audit.updated.format('YYYY-MM-DDThh:mm:ss'));
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);
        return formData;
    };

    private buildBulkFormData = (receivableVoluntaryResetBulk: ReceivableVoluntaryResetBulk, file?: File) => {
        const formData: FormData = new FormData();
        receivableVoluntaryResetBulk.councilId && formData.append('councilId', receivableVoluntaryResetBulk.councilId.toString());
        receivableVoluntaryResetBulk.tax && formData.append('tax', receivableVoluntaryResetBulk.tax.toString());
        receivableVoluntaryResetBulk.reset && formData.append('reset', receivableVoluntaryResetBulk.reset.format('YYYY-MM-DDThh:mm:ss'));
        receivableVoluntaryResetBulk.receivableIds &&
            formData.append('receivableVoluntaryResetBulk.receivableIds', receivableVoluntaryResetBulk.receivableIds.toString());
        receivableVoluntaryResetBulk.expired && formData.append('expired', receivableVoluntaryResetBulk.expired.format('YYYY-MM-DD'));
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);
        return formData;
    };

    /**
     * Loads the dates of a receivable voluntary reset.
     * @param receivableVoluntaryReset - the receivable voluntary reset
     */
    private loadDates(receivableVoluntaryReset: ReceivableVoluntaryReset) {
        receivableVoluntaryReset.expired = dateService.transformDate(receivableVoluntaryReset.expired)!;
        receivableVoluntaryReset.reset = dateService.transformDate(receivableVoluntaryReset.reset)!;
        receivableVoluntaryReset.audit!.created = dateService.transformDate(receivableVoluntaryReset.audit!.created)!;
        receivableVoluntaryReset.audit!.updated = dateService.transformDate(receivableVoluntaryReset.audit!.updated)!;
    }
}

const receivableVoluntaryResetApi: ReceivableVoluntaryResetApi = new ReceivableVoluntaryResetApi();
export default receivableVoluntaryResetApi;
