import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd/lib';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import { CensusUrbanProperty, ImportError } from '../../../../models/Entities';
import { CensusImportFieldType } from '../../../../models/Types';
import FormattedCellComponent from '../FormattedCellComponent/FormattedCellComponent';
import styles from './CensusUrbanPropertyTableComponent.module.scss';

/**
 * Returns the census urban property table component.
 * @returns the census urban property table  component.
 */
const CensusUrbanPropertyTableComponent: React.FC<Props> = (props) => {
    const { censuses } = props;

    /***VISUAL***/

    const columns: ColumnsType<CensusUrbanProperty> = [
        {
            dataIndex: 'icon',
            key: 'icon',
            width: 40,
            fixed: 'left',
            align: 'center'
        },
        {
            dataIndex: 'errors',
            key: 'errors',
            width: 50,
            fixed: 'left',
            align: 'center',
            render: (errors: ImportError<CensusImportFieldType>[]) => {
                if (errors && errors.length > 0) {
                    return (
                        <Tooltip title={<FormattedMessage id="censusDocuments.import.steps.2.status.error" />}>
                            <WarningOutlined className={`${styles.status} ${styles.error}`} />
                        </Tooltip>
                    );
                } else {
                    return <CheckCircleOutlined className={`${styles.status} ${styles.success}`} />;
                }
            }
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.fixNumber" />,
            dataIndex: 'fixNumber',
            key: 'fixNumber',
            width: 200,
            fixed: 'left',
            align: 'center',
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="FIX_NUMBER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.identifier" />,
            dataIndex: ['taxpayer', 'identifier'],
            key: 'identifier',
            width: 180,
            fixed: 'left',
            align: 'center',
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="TAXPAYER_IDENTIFIER">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.firstName" />,
            dataIndex: ['taxpayer', 'firstName'],
            key: 'firstName',
            width: 200,
            fixed: 'left',
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="TAXPAYER_FIRST_NAME">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.lastName" />,
            dataIndex: ['taxpayer', 'lastName'],
            key: 'lastName',
            width: 150,
            fixed: 'left',
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="TAXPAYER_LAST_NAME">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.secondLastName" />,
            dataIndex: ['taxpayer', 'secondLastName'],
            key: 'lastName',
            width: 150,
            fixed: 'left',
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="TAXPAYER_SECOND_LAST_NAME">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rights" />,
            dataIndex: 'rights',
            key: 'rights',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="RIGHTS">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rightsPercentage" />,
            dataIndex: 'rightsPercentage',
            key: 'rightsPercentage',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="RIGHTS_PERCENTAGE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.propertyLocation" />,
            dataIndex: 'propertyLocation',
            key: 'propertyLocation',
            width: 180,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="PROPERTY_LOCATION">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.propertyLocation2" />,
            dataIndex: 'propertyLocation2',
            key: 'propertyLocation2',
            width: 180,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="PROPERTY_LOCATION_2">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.propertyLocation3" />,
            dataIndex: 'propertyLocation3',
            key: 'propertyLocation3',
            width: 180,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="PROPERTY_LOCATION_3">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.cadastralReference" />,
            dataIndex: 'cadastralReference',
            key: 'cadastralReference',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="CADASTRAL_REFERENCE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.constructedArea" />,
            dataIndex: 'constructedArea',
            key: 'constructedArea',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="CONSTRUCTED_AREA">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.landArea" />,
            dataIndex: 'landArea',
            key: 'landArea',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="LAND_AREA">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.constructionValue" />,
            dataIndex: 'constructionValue',
            key: 'constructionValue',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="CONSTRUCTION_VALUE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.landValue" />,
            dataIndex: 'landValue',
            key: 'landValue',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="LAND_VALUE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.cadastralValue" />,
            dataIndex: 'cadastralValue',
            key: 'cadastralValue',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="CADASTRAL_VALUE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxableBase" />,
            dataIndex: 'taxableBase',
            key: 'taxableBase',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="TAXABLE_BASE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.classification" />,
            dataIndex: 'classification',
            key: 'classification',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="CLASSIFICATION">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxRate" />,
            dataIndex: 'taxRate',
            key: 'taxRate',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="TAX_RATE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.participationRate" />,
            dataIndex: 'participationRate',
            key: 'participationRate',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="PARTICIPATION_RATE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.bonusRate" />,
            dataIndex: 'bonusRate',
            key: 'bonusRate',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="BONUS_RATE">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.bonifiedAmount" />,
            dataIndex: 'bonifiedAmount',
            key: 'bonifiedAmount',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="BONIFIED_AMOUNT">
                    {value}
                </FormattedCellComponent>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.fee" />,
            dataIndex: 'fee',
            key: 'fee',
            align: 'center',
            width: 120,
            render: (value: string, census: CensusUrbanProperty) => (
                <FormattedCellComponent census={census} field="FEE">
                    {value}
                </FormattedCellComponent>
            )
        }
    ];

    return (
        <Table
            dataSource={censuses}
            columns={columns}
            sortDirections={['ascend', 'descend']}
            showSorterTooltip={false}
            pagination={false}
            className="table"
            scroll={{ x: 2400 }}
            expandable={{ childrenColumnName: 'taxpayers', defaultExpandAllRows: true }}
        />
    );
};
export default CensusUrbanPropertyTableComponent;

interface Props {
    censuses: CensusUrbanProperty[];
}
